function invoicePaginator(data) {

    $("#my-invoice-list .from").html(data.data.length);
    $("#my-invoice-list .total").html(data.total);
    if (data.last_page > 1) {
        var html = '<ul class="pagination pagination-sm pull-right">';
        var prev = "";
        var prevPage = data.current_page - 1;
        if (data.current_page - 1 < 1) {
            prev = "disabled";
            prevPage = 1;
        }
        html += '<li class="' + prev + '"><a href="#my-invoices" data-page="1" aria-label="Previous"><span aria-hidden="true">&lt;&lt;</span> </a> </li>';
        html += '<li class="' + prev + '"><a href="#my-invoices" data-page="' + prevPage + '" aria-label="First"><span aria-hidden="true"> &lt; </span> </a> </li>';

        var offset = 4;
        if (data.current_page - offset > 1) {
            html += '<li class="' + (data.current_page - offset) + '"><a href="#my-domains-list" data-page="' + (data.current_page - offset) + '" aria-label="Next"><span aria-hidden="true"> ... </span> </a> </li>';
        }
        for (var i = 1; i <= data.last_page; i++) {
            if (data.total <= offset * 2 + 1 || (data.current_page - offset <= i && data.current_page + offset >= i )) {
                var active = "";
                if (data.current_page == i) {
                    active = " active ";
                }
                html += '<li class="' + active + '"><a href="#my-invoices" data-page="' + i + '" aria-label="Next"><span aria-hidden="true"> ' + i + ' </span> </a> </li>';


            }
        }
        if (data.current_page + offset < data.last_page) {
            html += '<li class="' + (data.current_page + offset) + '"><a href="#my-invoices" data-page="' + (data.current_page + offset) + '" aria-label="Next"><span aria-hidden="true"> ... </span> </a> </li>';
        }
        var last = "";
        var nextPage = (data.current_page + 1);
        if (data.current_page + 1 > data.last_page) {
            last = "disabled";
            nextPage = data.last_page;
        }
        html += '<li class="' + last + '"><a href="#my-invoices"  data-page="' + nextPage + '" aria-label="Next"><span aria-hidden="true"> &gt; </span> </a> </li>';
        html += '<li class="' + last + '"><a href="#my-invoices" data-page="' + data.last_page + '" aria-label="Last"><span aria-hidden="true">&gt;&gt;</span> </a> </li>';

        html += '</ul>';
        return html;
    }
    return "";
}
var invoiceLock = false;
if ($("#my-invoice-list").length > 0) {
    renderInvoices();
    $("#my-invoice-list").find("table thead tr th").on('click', function () {
        if (!lock) {
            if ($(this).attr("data-ordering") != undefined) {
                if ($(this).attr("data-ordering") == "desc") {
                    $(this).removeAttr('data-ordering');

                } else {
                    $(this).attr("data-ordering", "desc");

                }
            } else {
                $(this).attr("data-ordering", "asc");
            }
            renderInvoiceFiltered();
        }

    });
    $('#my-invoice-list').find('[name=search-domains]').on('keyup change', function () {
        clearTimeout(filter);
        var filter = setTimeout(function () {
            renderInvoiceFiltered(true);
        }, 1000);
    });
}
function renderInvoices(loading, page, filter) {
    if (page == undefined) {
        page = 1;
    }
    if (filter == undefined) {
        filter = {}
    }
    if (loading == undefined ||  loading == false) {
        $("#invoice-loader").show();
    }
    var result = ajaxApiCall('/ajax/user/invoices?page=' + page, filter, 'GET');
    result.always(function () {
        invoiceLock = false;
        var response = $.parseJSON(result.responseText);
        var sorting = sortObj(response.data,'value');


        var res = "";
        $.each(sorting, function (index, elem) {
            res += renderInvoiceRow(elem);
        });
        $("#my-invoice-list tbody").html(res);

        $('.invoice-row').on('click', function () {

                var invoice = getInvoice($(this).data('invoice'));
                doModal(invoice,false, "PayModal");
                loader($("#PayModal .modal-body"));
                $("#PayModal .modal-dialog").addClass('modal-lg');
                invoice.always(
                    function () {
                        $("#PayModal .modal-body").html(invoice.responseText);
                        changePaymentMethod();
                        bankTransferComplite();
                        getPayModal();
                    }
                );


        });
        $("#my-invoice-list .pagination").remove();
        $("#my-invoice-list #invoice-loader").hide();
        $("#my-invoice-list").append(invoicePaginator(response));
        onInvoicePageClick();
    });


}
function renderInvoiceRow(row) {
    row.date = dateFormat((row.date).mysqlToDate());
    return '<tr class="invoice-row '+row.method+'" data-invoice="' + row.id + '"><td></td><td>' + invoiceNumberFormat(row.id) + '</td><td>' + row.date + '</td><td>' + methodFormat(row.method) + '</td><td class="text-right">' + row.amount + ' USD</td><td>' + renderStatus(row.status) + '</td></tr>';
}

function invoiceNumberFormat(number) {
    var numLength = 8;
    number = number.toString();
    if (number.length <= numLength) {
        var totalZero = numLength - number.length;
        var looper = "";
        for (var i = 1; i <= totalZero; i++) {
            looper += "0";
        }
        return looper + number;
    } else {
        return number;
    }
}
function methodFormat(method) {
    if (method == 'bank')
        return "Bank Transfer";
    if (method == "master")
        return "MasterCard";
    if (method == "tenpay")
        return 'TenPay';
    if (method == "alipay")
        return 'AliPay';
    if (method == "unionpay")
        return 'UnionPay';
    return ucFirst(method);
}

function ucFirst(str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

function renderStatus(status) {
    return '<div class="label-outline ' + status + '">' + ucFirst(status) + '</div>';
}
function onInvoicePageClick() {
    $(".pagination a").on('click', function () {
        renderInvoiceFiltered(true, $(this).data("page"));
    });
}
function renderInvoiceFiltered(term, page) {
    if (page == undefined) {
        page = 1;
    }
    if (!invoiceLock) {
        invoiceLok = true;
        var searchTerm = $("[name=search-domains]").val();
        var filters = {};
        var filterLength = $("#my-invoice-list table th").length;
        filterLength--;
        $.each($("#my-invoice-list table th"), function (i, item) {
            if ($(item).attr('data-filter') != undefined && $(item).attr('data-ordering') != undefined) {
                filters[$(item).attr('data-filter')] = $(item).attr('data-ordering');
            }

            if (filterLength == i) {
                var str = {term: searchTerm, filters: filters};
                renderInvoices(true, page,str );
            }
        });
    }
}


sortObj = function(obj, type, caseSensitive) {
    var temp_array = [];
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (!caseSensitive) {
                key = (key['toLowerCase'] ? key.toLowerCase() : key);
            }
            temp_array.push(key);
        }
    }
    if (typeof type === 'function') {
        temp_array.sort(type);
    } else if (type === 'value') {
        temp_array.sort(function(a,b) {
            var x = obj[a];
            var y = obj[b];
            if (!caseSensitive) {
                x = (x['toLowerCase'] ? x.toLowerCase() : x);
                y = (y['toLowerCase'] ? y.toLowerCase() : y);
            }
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    } else {
        temp_array.sort();
    }
    var temp_obj = {};
    for (var i=0; i<temp_array.length; i++) {
        temp_obj[temp_array[i]] = obj[temp_array[i]];
    }
    return temp_obj;
};