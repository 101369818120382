$.validator.setDefaults({
    onkeyup: function (element) {
        clearTimeout(timeoute);
        if (!$(element).valid()) {
            $(element).closest('.form-group').removeClass('has-error');
            timeoute = setTimeout(function () {
                $(element).closest('.form-group').removeClass('has-success');
                $(element).closest('.form-group').addClass('has-error');
            }, 1500)
        } else {
            $(element).next('.help-block').hide();
        }
    },

    highlight: function (element) {
        $(element).closest('.form-group').removeClass('has-success');
        $(element).closest('.form-group').addClass('has-error');

    },
    unhighlight: function (element) {
        $(element).closest('.form-group').removeClass('has-error');
    },
    errorElement: 'span',
    errorClass: 'help-block',
    errorPlacement: function (error, element) {
        if (element.parent('.input-group').length) {
            error.insertAfter(element.parent());
        } else {
            error.insertAfter(element);
        }

    },
    success: function (element) {
        $(element).closest('.form-group').removeClass('has-error');
        $(element).closest('.form-group').addClass('has-success');
    }
});
$(".form-validate").validate();
if ($("[name=is_organisation]:checked").val() == '1') {

    $('.trigger').show();
    $('.entity-hide').removeClass("person");
    $('.entity-hide').removeClass("legal");
    $('.entity-hide').addClass("legal");
    renderRequired();
} else {
    if ($("[name=is_organisation]:checked").val() === '0') {

        $('.trigger').show();
        $('.entity-hide').removeClass("person");
        $('.entity-hide').removeClass("legal");
        $('.entity-hide').addClass("person");
        renderRequired();
    }
}


$("[name=is_organisation]").change(function () {

    $('.trigger').show();
    $('.entity-hide').removeClass("person");
    $('.entity-hide').removeClass("legal");
    if ($(this).val() == '1') {
        $('.entity-hide').addClass("legal");
    } else {
        $('.entity-hide').addClass("person");
    }
    $(".form-validate").validate();
    renderRequired();

});

function renderRequired() {
    $(".form-validate [required]").removeAttr('required');
    $(".required").parent("div").find("input").prop("required", true);
    if ($('.entity-hide').hasClass("person")) {
        $(".legal-wrapper [required]").removeAttr('required');
        $(".person-wrapper").find('.required').parent("div").find("input").prop("required", true);

    }
    if ($('.entity-hide').hasClass("legal")) {
        $(".person-wrapper [required]").removeAttr('required');
        $(".legal-wrapper").find('.required').parent("div").find("input").prop("required", true);
    }

    $(".form-validate").validate();
    $(".form-validate").on('keyup change', function () {
        var elem = $('.form-validate [required]');
        activateButton(elem);
    });

}

$.validator.addMethod("zip", function (value, element) {
    // zip_number = zip_number.replace(/\(|\)|\s+|-/g, "");
    var zip_number = /^[a-zA-Z0-9 _.-]*$/;
    // return this.optional(element) || value.match(zip_number);
    return this.optional(element) || value.length >=4 &&
        value.match(zip_number);
}, "Please specify a valid zip/post code number");

$.validator.addMethod("tel", function (phone_number, element) {
    phone_number = phone_number.replace(/\(|\)|\s+|-/g, "");
    return this.optional(element) || phone_number.length > 9 &&
        phone_number.match(/^(\+|00)?(?:[0-9] ?){10,14}[0-9]$/);
}, "Please specify a valid phone number with your area code");
$.validator.addMethod( //override email with django email validator regex - fringe cases: "user@admin.state.in..us" or "name@website.a"
    'email',
    function (value, element) {
        return this.optional(element) || /(^[-!#$%&'*+/=?^_`{}|~0-9A-Z]+(\.[-!#$%&'*+/=?^_`{}|~0-9A-Z]+)*|^"([\001-\010\013\014\016-\037!#-\[\]-\177]|\\[\001-\011\013\014\016-\177])*")@((?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}\.?|[A-Z0-9-]{2,}\.?)$)|\[(25[0-5]|2[0-4]\d|[0-1]?\d?\d)(\.(25[0-5]|2[0-4]\d|[0-1]?\d?\d)){3}\]$/i.test(value);
    },
    'Verify you have a valid email address.'
);
$.validator.addMethod('ip', function (value, element) {
    var ip = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return this.optional(element) || value.match(ip);
}, 'Invalid IP address');
$.validator.addMethod('domain', function (value, element) {
    var ns = /(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63}$)/;
    return this.optional(element) || value.match(ns);
}, 'Please Provide Valid NS server');
var timeoute = false;

$(".form-validate").validate();

$(".form-validate").on('keyup', function () {
    var elem = $('.form-validate [required]');

    activateButton(elem);
});
$(".form-validate input").on('change', function () {
    $(this).valid();
});
setInterval(function () {
        if ($('.form-validate input:-webkit-autofill').val()) {

        if ($('.form-validate input:-webkit-autofill').valid()) {
            var elem = $('.form-validate [required]');
            activateButton(elem);
        }

    }
}, 1000);

function activateButton(elem) {
    var status = true;
    $.each(elem, function (index, item) {
        if ($(this).val() == "") {
            status = false;

        }
    });
    if ($(".required input[name=agreement]").length > 0 && $(".required input[name=agreement]:checked").length < 1) {
        status = false;
    }
    if ($(".legal input[name=sex]").length > 0 && $(".legal input[name=sex]:checked").length < 1) {
        status = false;
    }
    if ($(".person input[name=person_sex]").length > 0 && $(".person input[name=person_sex]:checked").length < 1) {
        status = false;

    }
    if (status) {

        if ($(".form-validate").validate().checkForm()) {
            $(".form-validate").valid();
            $(".validated-send").removeClass("disabled");
        }
        else {
            $(".validated-send").addClass("disabled");
        }
    } else {
        $(".validated-send").addClass("disabled");
    }
    if ($(".payment-methods-list [type=radio]:checked").length == 0 || $("[name=amount]").val() <= 0) {
        $(".fill-btn").addClass('disabled');
    }
}
$(".form-validate input").after('<span class="validation-icon"></span>');
$(".checkbox label input").after("<span class='replacer'></span>");
$(".radio label input").after("<span class='replacer'></span>");
$(".overwrite-field").click(function () {
    $(this).addClass("hidden");
    $("[name=amount]").removeClass("hidden").attr('autofocus', true);

});
$("#plus-balance").click(function () {
    $("[name=amount]").val(parseFloat($("[name=amount]").val()) + 10);
    $("[name=amount]").addClass("hidden");
    $(".overwrite-field").html('$ ' + $($("[name=amount]")).val()).removeClass("hidden");
    $("#amount-error").hide();
    var elem = $('.form-validate [required]');
    activateButton(elem);
    if ($("[name=amount]").val() < 0) {
        $("[name=amount]").val(0);
        $(".overwrite-field").html('$ ' + $($("[name=amount]")).val()).removeClass("hidden");
    }


});
$("#minus-balance").click(function () {
    $("[name=amount]").val(parseFloat($("[name=amount]").val()) - 10);
    $("[name=amount]").addClass("hidden");
    var elem = $('.form-validate [required]');
    activateButton(elem);
    $(".overwrite-field").html('$ ' + $($("[name=amount]")).val()).removeClass("hidden");
    $("#amount-error").hide();
    if ($("[name=amount]").val() < 0) {
        $("[name=amount]").val(0);
        $(".overwrite-field").html('$ ' + $($("[name=amount]")).val()).removeClass("hidden");
    }


});

$("[name=amount]").change(function () {
    if ($("[name=amount]").val() < 0) {
        $("[name=amount]").val(0);
    }
    $(".overwrite-field").html('$ ' + $(this).val()).removeClass("hidden");
    $(this).addClass("hidden");
    $("#amount-error").hide();
    $("[name=amount]").attr('autofocus', false);

});
$(".payment-methods-list [type=radio]").change(function () {
    $(".green-border").removeClass("green-border");
    $(this).parent("label").addClass("green-border");
    var elem = $('.form-validate [required]');
    activateButton(elem);
});

$.ajaxSetup(
    {
        headers: {
            'X-CSRF-Token': $('input[name="_token"]').val()
        }
    });
$("#payment-form").submit(function () {
    loader($("#payment-response"));
    var method = $("[name=pay-method]:checked").val();
    var amount = $("#amount").val();
    if (amount > 0 && method != "") {
        $("#PayModal").modal("show");
        $.get('/balance/fill/' + amount + '/' + method, function (data) {
            $("#payment-response").html(data);

            bankTransferComplite();
            changePaymentMethod();
            getPayModal();

        }).fail(function () {
            location.assign("/dashboard");
        });
    }
    return false;
});
var loaderHtml = '<div class="loader"><div><span></span><span></span><span></span></div></div>';

function loader(element) {

    element.html('<div class="loader"><div><span></span><span></span><span></span></div></div>');

}

function getDomainList() {
    var domains = $('[name=domains]').val().split('\n');
    var list = [];
    $.each(domains, function (index, item) {
        item = $.trim(item);
        if (item != "") {
            list.push(item);
        }
    });
    return uniques(list);
}
$("#search-btn").click(function (event) {
    event.preventDefault();
    if ($.trim($('[name=domains]').val()) != "") {
        var domains = getDomainList();
        renderDomainResult(domains);
    }
    return false;
});

function renderDomainResult(domains) {
    $("#transfer-result").html('');
    var rendered = '<div class="well">';
    if (domains.length > 1) {
        rendered += '<div class="list-row"><div class="checkbox disabled"><label><input name="check-all" type="checkbox" checked disabled><span class="replacer"></span><strong>Search Results</strong></label></div></div><div data-group="checkbox">';
    } else {
        rendered += '<div class="list-row"><div class="checkbox disabled"><label><strong>Search Result</strong></label></div></div><div data-group="checkbox">';
    }

    $.each(domains, function (index, item) {
        rendered += '<div class="list-row"  data-domain=\'' + clearId(item) + '\'"><div class="row"><div class="checkbox  disabled col-sm-6 col-xs-12 dom-name"><label><div class="text-overflow"><input type="checkbox" disabled><span class="replacer"></span>';
        rendered += '<span class="bg-well">' + escapeHtml(item) + '</span></div><!--end-texoverflow--></label></div><!--end-checkbox-->';
        rendered += '<div class="col-sm-6 col-xs-12  dom-status"></div><div class="col-sm-6 col-xs-12  dom-price"> ' + loaderHtml + ' </div> <div class="col-sm-6 col-xs-12  dom-action"></div>';
        rendered += '</div><!--end-row--></div><!--end-list-row-->';
    });
    rendered += "<div class='btn-list pull-right'><button id='add-to-cart' class='bolder btn btn-primary  disabled'><span class='fa fa-shopping-cart mgr-5'></span> Add Selected to Cart</button>";
    rendered += "<a href='/cart/view' class='btn btn-primary-outline bolder  pull-right'>View Cart</a></div></div>";

    rendered += '</div>';
    $("#search-result").html(rendered);
    getDomainResult(domains);
}

function clearId(item) {
    item = item.toLowerCase();
    item = item.replace(/ /g, "_");
    item = item.replace(/[^a-zA-Z0-9]+/g, "");
    return 'dom' + item.replace(/'/g, "");
}

function getDomainResult(domains) {
    var list = clearDomainList(domains);
    var requestInterval = setInterval(function () {
        if (list.length > 0) {
            var result = ajaxApiCall("/ajax/domain/" + list[0]);
            removeItemByValue(list, list[0]);

            result.always(function (data) {
                var resp = $.parseJSON(data.responseText);
                var rowId = clearId(resp.domain);
                if (result.status == 406) {
                    setInvalidExtension(rowId);
                } else {
                    var response = $.parseJSON(result.responseText);
                    if (response.available) {
                        setAvailable(rowId, response);
                        checkAllListenner();
                    } else {
                        setUnavailable(rowId, list[0], "<pre>" + response.whois + "</pre>");
                    }
                }
            });
        } else {
            clearInterval(requestInterval);
        }
    }, 1000);
}
function checkAllListenner() {
    $('[name=check-all]').on('change', function () {

        if ($(this).is(':checked')) {
            $(this).parents('.well').find('input[type=checkbox]').not(':disabled').prop('checked', true);
            $(".bulk-renew").removeClass("disabled")

        } else {
            $(this).parents('.well').find('input[type=checkbox]').not(':disabled').prop('checked', false);
            $(".bulk-renew").addClass("disabled");
        }

    });
    $("[data-group=checkbox] input[type=checkbox]").on('change', function () {
        var count = $(this).parents('[data-group=checkbox]').find('input[type=checkbox]:checked').not(':disabled').length;
        var countBox = $(this).parents('[data-group=checkbox]').find('input[type=checkbox]').not(':disabled').length;
        if (count == countBox) {
            $(this).parents('.well').find('[name=check-all]').prop('checked', true);
        } else {
            $(this).parents('.well').find('[name=check-all]').prop('checked', false);
        }
        if ($("#my-domains-list tbody input[type=checkbox]:checked").length > 0) {
            $(".bulk-renew").removeClass("disabled")
        } else {
                $(".bulk-renew").addClass("disabled");

        }
        buttonActivation();
    });

}


function setAdded(domain) {
    rowId = clearId(domain);
    var btn = '<div class="btn-list  mrg-0"><button class="btn btn-primary disabled btn-sm mrg-0"><strong>Added</strong></button>';
    btn += '<a href="/cart/view" class="btn btn-primary btn-sm"><strong>View Cart</strong></a></div>';
    $('[data-domain=' + rowId + "] .dom-action").html(btn);
    $('[data-domain=' + rowId + "] .checkbox").addClass("disabled");
    $('[data-domain=' + rowId + "] .checkbox input[type=checkbox]").attr("disabled", "disabled");

}

function setInvalidExtension(rowId) {
    $('[data-domain=' + rowId + "] div .dom-price").html("");
    $('[data-domain=' + rowId + "] .dom-status").html("<span class='label label-danger'> Unsupported Extension</span>");
}
function setAvailable(rowId, result) {
    $('[data-domain=' + rowId + "] .dom-status").html("<span class='label label-success'>Available</span>");
    var prices = result.prices;
    if (prices.length > 1) {
        <!-- Split button -->
        var btn = '<div class="btn-group">';
        btn += '<button type="button" data-loading-text="Adding..." id="btn-' + rowId + '" data-domain="' + result.domain + '" data-year="' + prices[0].year + '" class="btn btn-sm btn-primary price-sender"><span class="fa fa-shopping-cart mgr-5"></span><strong>Add to Cart</strong></button>';
        btn += '<button type="button" class="btn price-sender btn-primary btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">';
        btn += '<span class="caret"></span>';
        btn += '<span class="sr-only">Toggle Dropdown</span>';
        btn += '</button>';
        btn += '<ul class="dropdown-menu">';
        $.each(prices, function (index, price) {
            btn += '<li><a href="#" data-row="' + rowId + '" class="price-changer" data-year="' + price.year + '" data-price="' + price.price + '">' + price.year + ' Year/s @ $' + price.price + ' USD</a></li>';
        });
        btn += '</ul>';
        btn += '</div>';
    } else {
        var btn = '<button data-loading-text="Adding..." class="btn mrg-5-0 btn-sm pull-right  btn-primary price-sender" id="btn-' + rowId + '" data-domain="' + result.domain + '" data-year="' + prices[0].year + '" btn-sm"><span class="fa fa-shopping-cart mgr-5"></span><strong>Add to Cart</strong></button>';
    }
    $('[data-domain=' + rowId + "] .dom-action").html(btn);
    $('[data-domain=' + rowId + "] .checkbox").removeClass("disabled");
    updatePrice(rowId, prices[0].price);
    $('[data-domain=' + rowId + "] .checkbox input[type=checkbox]").removeAttr("disabled").attr("checked", "checked");
    $('[name=check-all]').removeAttr("disabled").attr("checked", "checked").parent("label").parent(".checkbox").removeClass("disabled");
    $('#add-to-cart').removeClass("disabled");
    $(".price-changer").on('click', function (event) {
        event.preventDefault();
        updatePrice($(this).data('row'), $(this).data('price'), $(this).data('year'));
        $(".open").removeClass("open");
        return false;
    });
    $("#btn-" + rowId).click(function () {
        addToCart($(this));
    });
    $("#add-to-cart").click(function () {
        sendAllSelected();
    });

}
function addToCart(item) {

    var vars = {};
    vars.domain = item.data('domain');
    vars.year = item.data('year');
    item.button('loading');
    var method = "POST";
    var res = ajaxApiCall("/ajax/cart", vars, method);
    res.always(function () {
        setAdded(vars.domain);
        buttonActivation();
    });

}
function buttonActivation() {
    var count = $('[data-group=checkbox]').find('input[type=checkbox]:checked').not(':disabled').length;
    if (count > 0) {
        $("#add-to-cart").removeClass("disabled");
    } else {
        $("#add-to-cart").addClass("disabled");
    }
}
function sendAllSelected() {
    var vars = {};
    vars.domains = [];
    var items = $('#search-result').find('[data-group=checkbox]').find('input[type=checkbox]:checked').not(':disabled').parents(".list-row").find(".price-sender");
    $.each(items, function (index, elem) {

        if (!isEmpty($(elem).data('domain'))) {
            vars.domains.push({domain: $(elem).data('domain'), year: $(elem).data('year')});
            $(elem).button('loading');

        }

    });

    var method = "POST";
    var res = ajaxApiCall("/ajax/cart", vars, method);
    res.always(function () {
        $.each(vars.domains, function (i, el) {
            setAdded(el.domain);
        });

        buttonActivation();
    });
}
function updateButtonYear(name, year) {
    $("#btn-" + name).attr('data-year', year);
}
function updatePrice(item, price, year) {
    $('[data-domain=' + item + "] .dom-price").html("<span class='price-text'>$" + price + " USD</span>");
    if (year != undefined) {
        updateButtonYear(item, year);
    }
}
function setUnavailable(rowId, domain, whois) {
    $('[data-domain=' + rowId + "] div .dom-price").html("");
    $('[data-domain=' + rowId + "] .dom-status").html("<span class='label label-danger'>Taken</span>");
    $('[data-domain=' + rowId + "] .dom-action").html("<div class='btn-list mrg-0'><a class='btn btn-xs btn-primary-outline' href='http://" + domain + "' target='_blank'><strong>WWW</strong></a> <a class='btn whois-btn  btn-xs btn-primary-outline'><strong>Whois</strong></a></div> ");
    $('[data-domain=' + rowId + "] .dom-action .whois-btn").click(function () {
        doModal(whois, null, "Unavailable");
    });
}
function clearDomainList(domains) {
    var result = [];
    var reqexp = new RegExp(/(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63}$)/);
    $.each(domains, function (index, domain) {
        rowId = clearId(domain);
        if (domain.match(reqexp)) {
            result.push(domain)
        } else {
            $('[data-domain=' + rowId + "] div .dom-price").html("");
            $('[data-domain=' + rowId + "] .dom-status").html("<span class='label label-danger'>Invalid Domain</span>");
        }
    });
    return result;

}
function ajaxApiCall(url, vars, method) {
    if (method == undefined) {
        method = 'GET';
    }
    var ajax = $.ajax({
        url: url,
        data: vars,
        headers: {
            'X-CSRF-Token': $('input[name="_token"]').val()
        },
        dataType: 'jsonp',
        type: method
    });
    return ajax;
}
function removeItemByValue(arr, value) {
    var index = arr.indexOf(value);
    if (index >= 0) {
        arr.splice(index, 1);
    }
    return arr;
}
function uniques(arr) {
    var a = [];
    for (var i = 0, l = arr.length; i < l; i++)
        if (a.indexOf(arr[i]) === -1 && arr[i] !== '')
            a.push(arr[i]);
    return a;
}

function isValidDomain(domain) {
    var symbols = new RegExp(/(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63}$)/);

    return domain.match(symbols);
}
function escapeHtml(unsafe) {
    return unsafe
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
}
function doModal(formContent, z_index, id) {
    var addZ = "";
    var newID = 'dynamicModal';
    if (!isEmpty(z_index)) {
        addZ = "topModal";
    }
    if (!isEmpty(id)) {
        newID = id;
    }

    var html = '<div id="' + newID + '" class="modal ' + addZ + ' fade" tabindex="-1" role="dialog" aria-labelledby="confirm-modal" aria-hidden="true">';
    html += '<div class="modal-dialog"><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>';
    html += '<div class="modal-content">';
    html += '<div class="modal-body">';
    html += '<p>';
    html += formContent;
    html += '</p>';
    html += '</div>';  // content
    html += '</div>';  // dialog
    html += '</div>';  // modalWindow
    $('body').append(html);
    $("#" + newID).modal();
    $("#" + newID).modal('show');

    $('#' + newID).on('hidden.bs.modal', function (e) {
        $('#' + newID).remove();
    });

}

function getCurrentCart() {
    var elements = ajaxApiCall("/ajax/cart/current", false);
    renderCurrentCart(elements, "#current-cart");

}

function renderCurrentCart(elements, selector) {

    elements.always(function () {
        var domains = $.parseJSON(elements.responseText);
        var rendered = '<div class="well">';
        if (Object.keys(domains).length > 1) {
            rendered += '<div class="list-row"><div class="checkbox"><label><input name="check-all" type="checkbox" checked><span class="replacer"></span><strong>Just added</strong></label>';
        } else {
            rendered += '<div class="list-row"><div class="checkbox disabled"><label><strong>Just added</strong></label>';

        }

        rendered += "<div class='btn-list pull-right' style='margin:-15px 0!important'><a href='#delete-selected' id='delete-selected' class='btn btn-sm btn-primary-outline bolder'>Delete Selected</a>";
        rendered += "<a href='/ajax/cart/flush' class='btn btn-sm btn-primary-outline bolder'>Empty Cart</a></div></div></div><div data-group='checkbox'>";

        if (elements.status == 200) {
            $.each(domains, function (index, item) {
                rendered += '<div class="list-row"  data-domain="' + clearId(index) + '"><div class="row"><div class="checkbox col-xs-9 dom-name"><label><div class="text-overflow"><input type="checkbox" data-domain="' + index + '" data-year="' + item.year + '" checked><span class="replacer"></span>';
                rendered += '<span class="bg-well">' + escapeHtml(index) + '</span></div><!--end-texoverflow--></label></div><!--end-checkbox-->';
                rendered += "<div class='col-xs-4 '><div class='form-group dom-per mrg-5-0 form-group-sm'><select  class='form-control reg-year'>";
                $.each(item.prices, function (i, n) {
                    var sel, appendix = "";
                    if (item.year == n.year) {
                        sel = "selected";
                    }

                    if (n.year > 1) {
                        appendix = 's';
                    }
                    rendered += "<option " + sel + " value='" + n.price + "' data-year ='" + n.year + "'>" + n.year + " year" + appendix + "</option>";
                });
                rendered += '</select></div></div><!--form/col-->';
                rendered += '<div class="col-xs-9  dom-price"> </div> ';
                rendered += '<div class="col-xs-1 remove-div"><a href="#"  data-domain ="' + index + '" class="delete-item"><span class="pdng-10-0  text-muted fa-lg fa fa-trash-o"></span></a></div>';

                rendered += '</div><!--end-row--></div><!--end-list-row-->';
            });
        } else {
            rendered += "<p class='text-muted mrg-5-0'>There is no just added domains</p>"
        }
        rendered += '</div><!--end well--><p></p>';
        $(selector).html(rendered);
        $.each($(".list-row"), function () {
            updatePrice($(this).data('domain'), $(this).find(".reg-year").val());
        });

        onPricechange();
        checkAllListenner();
        deleteSelected();
        cart();
        $("[name=hidden]").trigger("change");

        $(".delete-item").on('click', function () {
            deleteCartItem($(this).data("domain"));
            return false;
        });
    });
}
function onPricechange() {

    $(".reg-year").on("change", function () {

        var getPrice = $(this).val();
        var item = $(this).parents(".list-row").data("domain");

        $(this).parents(".list-row").find('.dom-name input[type=checkbox]').data('year', $(this).find(":selected").data('year'));
        updatePrice(item, getPrice);
        $("[name=hidden]").trigger("change");
    });


}
function getRecentCart() {
    var elements = ajaxApiCall("/ajax/cart/recent", false);
    renderRecentCart(elements, "#recent-cart");
}

function renderRecentCart(elements, selector) {

    elements.always(function () {
        var domains = $.parseJSON(elements.responseText);

        if (elements.status == 200) {
            var rendered = '<div class="well">';
            if (Object.keys(domains).length > 1) {
                rendered += '<div class="list-row"><div class="checkbox"><label><input name="check-all" type="checkbox"><span class="replacer"></span><strong>Previously added </strong></label></div></div><div data-group="checkbox">';
            } else {
                rendered += '<div class="list-row"><div class="checkbox disabled"><label><strong>Previously added </strong></label></div></div><div data-group="checkbox">';

            }
            $.each(domains, function (index, item) {
                rendered += '<div class="list-row"  data-domain="' + clearId(index) + '"><div class="row"><div class="checkbox col-xs-9 dom-name"><label><div class="text-overflow"><input type="checkbox"  data-domain="' + index + '" data-year="' + item.year + '"   ><span class="replacer"></span>';
                rendered += '<span class="bg-well">' + escapeHtml(index) + '</span></div><!--end-texoverflow--></label></div><!--end-checkbox-->';
                rendered += "<div class='col-xs-4 '><div class='form-group  mrg-5-0 dom-per form-group-sm'><select  class='form-control reg-year'>";
                $.each(item.prices, function (i, n) {
                    var sel, appendix = "";
                    if (item.year == n.year) {
                        sel = "selected";
                    }

                    if (n.year > 1) {
                        appendix = 's';
                    }
                    rendered += "<option " + sel + " data-year ='" + n.year + "' value='" + n.price + "'>" + n.year + " year" + appendix + "</option>";
                });
                rendered += '</select></div></div><!--form/col-->';
                rendered += '<div class="col-xs-5  dom-price"> </div> ';
                rendered += '<div class="col-xs-4"><span class="price-text">' + item['date'] + '</span></div> ';
                rendered += '<div class="col-xs-1"><a href="#" data-domain ="' + index + '" class="delete-item"><span class="pdng-10-0  text-muted fa-lg fa fa-trash-o"></span></a></div>';

                rendered += '</div><!--end-row--></div><!--end-list-row-->';
            });
            rendered += '</div><!--end well--><p></p>';
        }

        $(selector).html(rendered);
        $.each($(".list-row"), function () {
            updatePrice($(this).data('domain'), $(this).find(".reg-year").val());
        });
        onPricechange();
        checkAllListenner();
        deleteSelected();
        cart();

        checkRecentAdded();
        $(".delete-item").on('click', function () {
            deleteCartItem($(this).data("domain"));
            return false;
        });
    });
}

function deleteCartItem(domain) {
    var res = ajaxApiCall("/ajax/cart/delete/" + domain, false);
    res.always(function () {
        if (res.responseText == 'refresh') {
            location.reload();
        }
        $(".list-row[data-domain=" + clearId(domain) + "]").find("input[type=checkbox]").trigger('change').attr('checked', false);
        $(".list-row[data-domain=" + clearId(domain) + "]").hide();
    });

}

function deleteSelected() {
    $("#delete-selected").on("click", function () {
        var vars = {};
        vars.domains = [];
        $.each($(".dom-name input[type=checkbox]:checked"), function (item, index) {
            vars.domains.push({domain: $(index).data('domain')});
        });
        var res = ajaxApiCall("/ajax/cart/delete", vars, 'POST');
        res.always(function () {
            $.each(vars.domains, function (i, el) {
                if (res.responseText == 'refresh') {
                    location.reload();
                }
                $(".list-row[data-domain=" + clearId(el.domain) + "]").find("input[type=checkbox]").trigger('change').prop('checked', false);
                $(".list-row[data-domain=" + clearId(el.domain) + "]").hide();

            });

            buttonActivation();
        });

        return false;
    });

}

function checkRecentAdded() {
    $("#recent-cart [data-group=checkbox] input[type=checkbox]").on("change", function () {
        if (!$(this).hasClass("rebooted")) {
            var result = ajaxApiCall("/ajax/domain/" + $(this).data("domain"));
            $(this).addClass("rebooted");
            result.always(function (data) {
                var response = $.parseJSON(result.responseText);
                if (!response.available) {
                    var rowId = clearId(response.domain);

                    $('[data-domain=' + rowId + "] .dom-per").html("<span class='label label-danger'>Taken</span>");
                    $('[data-domain=' + rowId + "] .dom-price").removeClass("col-xs-5").addClass("col-xs-9").html("<div class='btn-list pull-right mrg-0'><a class='btn btn-xs btn-primary-outline' href='http://" + response.domain + "' target='_blank'><strong>WWW</strong></a> <a class='btn whois-btn  btn-xs btn-primary-outline'><strong>Whois</strong></a></div> ").next("div").remove();
                    $('[data-domain=' + rowId + "] .dom-price .whois-btn").click(function () {
                        doModal(response.whois, null, 'whois-modal');
                    });
                    $('[data-domain=' + rowId + "] .replacer").css("visibility", "hidden").prev().prop('checked', false);

                    ajaxApiCall("/ajax/cart/delete/" + response.domain, false);
                }
            });
        }

    });
    cart();
}
function cart() {

    $("input[type=checkbox]").on("change", function () {
        var list = [];

        $.each($('[data-group=checkbox] input[type=checkbox]:checked'), function (index, elem) {
            var price = $(this).parents(".list-row").find("select").val();
            var elem = {domain: $(this).data("domain"), period: $(this).data("year"), price: price};
            list.push(elem);
            renderCart(list);
            configureListener();
        });
        if ($('[data-group=checkbox] input[type=checkbox]:checked').length == 0) {
            var rendered = "<div class='well'>";
            rendered += '<div class="list-row"><strong>Total Amount</strong></div>';
            rendered += '<div class="list-row"><div class="mrg-5-0">Domains selected <p class="pull-right text-right">0</p></div></div>';
            rendered += '<p class="pull-right  mrg-5-0" style="margin-right:-10px!important">US$ 0</p>';
            rendered += '<button class="btn btn-block disabled btn-primary mrg-10-0" data-loader="Please Wait...">Continue to Configure</button>';
            rendered += "</div>";
            $("#cart-zone").html(rendered);
            configureListener();
        }
    });
}

function renderCart(list) {
    var price = 0;
    var count = 0;
    $.each(list, function (index, item) {
        price += +item.price;
        count++;
    });
    var rendered = "<div class='well'>";
    rendered += '<div class="list-row"><strong>Total Amount</strong></div>';
    rendered += '<div class="list-row"><div class="mrg-5-0">Domains selected <p class="pull-right text-right">' + count + '</p></div></div>';
    rendered += '<p class="pull-right total-text mrg-5-0" style="margin-right: -10px!important">US$ ' + price.toFixed(2) + '</p>';
    rendered += '<button class="btn btn-block btn-primary mrg-10-0" data-loader="Please Wait...">Continue to Configure</button>';
    rendered += "</div>";
    $("#cart-zone").html(rendered);

    configureListener();
}
function configureListener() {
    onPricechange();
    $("#cart-zone button").on('click', function () {
        $(this).button('loading');
        addToConfigure();
        return false;
    })
}
$(document).ready(function () {
    calculateTotal();

    confPriceUpdate('.price-select');
    $('body').prepend("<div class='loader-wrapper'><div class='loader-inner'></div></div>");
    $(".loader-inner").animate({
        width: "100%"
    }, {
        duration: 1000, complete: function () {
            $(".loader-wrapper").remove();
        }
    });
    $('input[type=file]').bootstrapFileInput();

});

$("[aria-type=loader]").on('click', function () {
    $(".loader-close").alert("close");
    $(".loader-wrapper").remove();

    $('body').prepend("<div class='loader-wrapper'><div class='loader-inner'></div></div>");
    $(".loader-inner").css("width", 0).animate({
        width: "30%"
    }, {duration: 1000,});

});

$(function () {

    $('[type=date]').datepicker({
        format: 'yyyy-mm-dd',
        autoclose: true,
        startView: "decade"
    });

});
function addToConfigure() {

    var domains = [];
    $.each($('[data-group=checkbox] input[type=checkbox]:checked'), function (item, data) {

        domains.push({domain: $(this).data('domain'), year: $(this).data('year')});
    });
    var vars = {};
    vars.domains = domains;
    var res = ajaxApiCall("/ajax/cart/configure", vars, 'POST');
    res.always(function () {
        if (res.responseText == 'ok') {
            window.location.href = "/cart/configure";
        }
    });
    return domains;
}
function affixWidth() {
    // ensure the affix element maintains it width
    var affix = $('.affixed-element');
    var width = affix.width();
    affix.width(width);
}

$(document).ready(function () {

    affixWidth();
    var timeoute = false;
    $("[type=domain]").on('keyup', function () {
        clearTimeout(timeoute);
        var element = this;
        if (!$(element).valid()) {
            $(element).closest('.form-group').removeClass('has-error');
            timeoute = setTimeout(function () {
                $(element).closest('.form-group').removeClass('has-success');
                $(element).closest('.form-group').addClass('has-error');
            }, 1500)
        } else {
            $(element).next('.help-block').hide();
        }

    });

    var timeoute = false;
    $("[type=zip]").on('keyup', function () {
        clearTimeout(timeoute);
        var element = this;
        if (!$(element).valid()) {
            $(element).closest('.form-group').removeClass('has-error');
            timeoute = setTimeout(function () {
                $(element).closest('.form-group').removeClass('has-success');
                $(element).closest('.form-group').addClass('has-error');
            }, 1500)
        } else {
            $(element).next('.help-block').hide();
        }

    });

});

function bankTransferComplite() {
    $("#mark-paid").on('click', function () {
        doModal(loaderHtml, true, 'confirmModal');
        //$("#confirmModal").next('.modal-backdrop').addClass("removable").css("z-index", '1051');
        var invoice = $('#invoice').data('invoice');
        $.get('/bank/transaction', {invoice: invoice}, function (data) {
            $("#confirmModal .modal-body").html(data);
            $(function () {
                $('input[type=file]').bootstrapFileInput();
                $('[type=date]').datepicker({
                    format: 'yyyy-mm-dd',
                    autoclose: true,
                    startView: "day"
                });

            });
        });
    });

    $('#bankConfirm').next('.modal-backdrop').remove();

    $('#bankConfirm').remove();
    $('.removable').remove();
    $('.removable').remove();
}

function changePaymentMethod() {
    $("#method").on('change', function () {
        var data = {payment_method: $(this).val(), invoice_id: $("#invoice").data('invoice')};
        res = ajaxApiCall("/ajax/invoice/method", data, "PUT");
        res.always(function () {
            var invoice = getInvoice($("#invoice").data('invoice'));
            loader($("#PayModal .modal-body"));
            invoice.always(
                function () {
                    $("#PayModal .modal-body").html(invoice.responseText);
                    changePaymentMethod();
                    bankTransferComplite();
                    getPayModal();
                }
            );
        });
    });

}


function getInvoice(id) {
    var data = null;
    return ajaxApiCall("/ajax/invoice/" + id, data, "GET");
}
function getPayModal() {
    bankTransferComplite();
    stripeModal();
    paypalModal();
    alipayModal();
    balanceModal();
}
function stripeModal() {
    getMethod('stripe');
}
function balanceModal() {
    getMethod('balance');
}
function alipayModal() {
    getMethod('alipay');
    getMethod('unionpay');
    getMethod('tenpay');
}
function paypalModal() {
    getMethod('paypal');
}

function getMethod(method) {
    $("[data-type=" + method + "-btn]").on('click', function () {
        $(this).button('loading');
        var invoiceId = $('#invoice').data('invoice');
        if (!isEmpty(invoiceId)) {
            var blt = $.get('/payment/' + method + '/' + invoiceId, function (data) {
                if(method != 'balance'){
                    $('body').append(data);

                }
            });

            if(method == 'balance'){

                statusModal(blt);
            }
        }
    })
}
$('.whole-price').on('click', function () {
    doModal(loaderHtml);
    var slab = $(this).data('slab');
    $.get('/ajax/whole-price/' + slab, function (data) {
        $("#dynamicModal .modal-dialog").addClass('modal-lg');
        $("#dynamicModal .modal-body").html(data);
        tabChange();
    });

});

function tabChange() {
    $('.chooser').on('mouseover', function () {
        var slab = $(this).data('slab');
        $('.modal-price .active').removeClass('active');
        $('.' + slab).addClass('active');
        $('.' + slab + '-tariff').addClass('active');
    });
}
$('.tariff-continue').on('click', function (e) {
    if (!$(this).hasClass('disabled')) {
        $("#PayModal").modal("show");
        var slab = $('[name=slab_id]').val();
        var method = $('[name=pay-method]:checked').val();
        $.post('/tariff/select/payment', {slab: slab, p_method: method}, function (data) {
            $("#PayModal .modal-dialog").addClass('modal-lg');
            $("#PayModal .modal-body").html(data);
            bankTransferComplite();
            changePaymentMethod();
            getPayModal();
        });
    }
    return false;
});


function Latin2(obj) {
    if (/^[a-zA-Z0-9.\-]*?$/.test(obj.value)) {
        obj.defaultValue = obj.value;
        $("#alerter").fadeOut('slow');
    }
    else {
        obj.value = obj.defaultValue;

        $("#alerter").show();
    }
}
function whoisx(dom) {
    $(".dom_w").removeClass("dom_w");
    $(".cler").remove();
    var temp = dom.replace(".", "");
    $("#button_" + temp).html('<div class="cler backs"></div><a class="btn_col dom_w" onclick="whoisx(\'' + dom + '\')" href="javascript:void(0);">Whois info</a>');
    $.get("/whois/ajax-domain", {domain: dom}).done(function (data) {
        var temp = dom.replace(".", "");
        $("#button_" + temp).html('<div class="cler"><div class="sep"></div></div><a class="btn_col dom_w" onclick="whoisx(\'' + dom + '\')" href="javascript:void(0);">Whois info</a>');
        $("#whoisresult").html('<h3 class="wois_dom"><b>' + dom + ' </b> whois information</h3><pre>' + data + '</pre>');
        $("#whoisresult").slideDown('slow');

    });
}
function check() {
    $("#result").html('<table id="table"></table>');
    $("input:checkbox[name=tld]:checked").each(function () {
            var domain = $("#sld").val() + $(this).val();
            var temp = domain.replace(".", "");
            $("#table").append('<tr id="' + temp + '"><td class="domainname">' + domain + '</td><td class="status" id="status_' + temp + '"><div ><img src="/img/loading.gif"></div></td><td class="linker" id="button_' + temp + '"></td></tr>');
            $.get("/whois/ajax-available", {sld: $("#sld").val(), tld: $(this).val()}).done(function (data) {
                if (data == "ok") {
                    $("#status_" + temp).html("available");
                    $("#" + temp).addClass("available");
                    $("#button_" + temp).html('');
                } else {
                    $("#button_" + temp).html('<a class="btn_col" onclick="whoisx(\'' + domain + '\')" href="javascript:void(0);">Whois info</a>');
                    $("#status_" + temp).html("not available");
                    $("#" + temp).addClass("notavailable");
                }

            });

        }
    );
}

$(function () {
    $("#sld").keyup(function () {
        if ($("#sld").val() != "") check();
    });
    var liuk1 = true;
    $("#checkall").click(function (event) {
        liuk1 = false;
        if ($("#checkall").is(':checked')) {
            $("input:checkbox[name=tld]").prop('checked', true);
        } else {
            $("input:checkbox[name=tld]").prop('checked', false);
        }
        ;
    });
    $(".tldblock input:checkbox").click(function (event) {


        if ($("#sld").val() != "") check();
        var liuk = true;
        $("input:checkbox[name=tld]").each(function () {
                if (!$(this).is(':checked')) {
                    liuk = false
                }

            }
        );
        if (liuk1) {
            if (liuk) {
                $("#checkall").prop('checked', true);
            } else {
                $("#checkall").prop('checked', false);
            }
        } else {
            liuk1 = true;
        }

    });

});