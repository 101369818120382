function confDomain() {
    $('[name=type]').on('change', function () {
        if ($(this).val() == 'individual') {
            $('#name').parents('.form-group').removeClass('hidden');
            $('#last_name').parents('.form-group').removeClass('hidden');
            $('#org_name').parents('.form-group').addClass('hidden');
            $('.admin-contact').addClass('is-person').removeClass('is-entity');
        } else {
            $('.admin-contact').removeClass('is-person').addClass('is-entity');
            $('#name').parents('.form-group').addClass('hidden');
            $('#last_name').parents('.form-group').addClass('hidden');
            $('#org_name').parents('.form-group').removeClass('hidden');
        }
    });
    $('[name=same_as_billing]').on('change', function () {

        if ($(this).is(':checked')) {
            $('.tech-contact').addClass('same-as');
        } else {
            $('.tech-contact').removeClass('same-as');
        }

    });
    $('[name=same_as_admin]').on('change', function () {
        if ($(this).is(':checked')) {
            $('.bill-contact').addClass('same-as');
        } else {
            $('.bill-contact').removeClass('same-as');
        }

    });
    $('[name=same_registrant]').on('change', function () {
        if ($(this).is(':checked')) {
            $('.admin-contact').addClass('same-as-registrant');
        } else {
            $('.admin-contact').removeClass('same-as-registrant');
        }
    });
    $('.registrant input[name=name]').on('change keyup', function () {
        $('[name=same_registrant]:checked').parents('.admin-contact').find('[name=admin_name]').val($(this).val()).trigger('change');
    });

    $('.registrant input[name=last_name]').on('change keyup', function () {
        $('[name=same_registrant]:checked').parents('.admin-contact').find('[name=admin_last_name]').val($(this).val()).trigger('change');
    });

    $('.registrant select[name=country]').on('change keyup', function () {
        $('[name=same_registrant]:checked').parents('.admin-contact').find('[name=admin_country]').val($(this).val()).trigger('change');
    });


    $('.registrant input[name=city]').on('change keyup', function () {
        $('[name=same_registrant]:checked').parents('.admin-contact').find('[name=admin_city]').val($(this).val()).trigger('change');
    });

    $('.registrant input[name=address1]').on('change keyup', function () {
        $('[name=same_registrant]:checked').parents('.admin-contact').find('[name=admin_address1]').val($(this).val()).trigger('change');
    });

    $('.registrant input[name=zip]').on('change keyup', function () {
        $('[name=same_registrant]:checked').parents('.admin-contact').find('[name=admin_zip]').val($(this).val()).trigger('change');
    });

    $('[name=same_registrant]').on('change', function () {
        if ($(this).is(':checked')) {
            $('[name=admin_address1]').val($('.registrant input[name=address1]').val()).trigger('change');
            $('[name=admin_name]').val($('.registrant input[name=name]').val());
            $('[name=admin_last_name]').val($('.registrant input[name=last_name]').val()).trigger('change');
            $('[name=admin_city]').val($('.registrant input[name=city]').val()).trigger('change');
            $('[name=admin_zip]').val($('.registrant input[name=zip]').val()).trigger('change');
            $('[name=admin_country]').val($('.registrant select[name=country]').val()).trigger('change');
        }
    });

    $('.admin-contact input').on('change keyup', function () {
        var field = $(this).data('field');
        var val = $(this).val();
        $('.same-as .well-title input[type=checkbox]:checked').parents('.well').find('[data-field=' + field + ']').val(val);
    });
    $('.admin-contact select').on('change keyup', function () {
        var field = $(this).data('field');
        var val = $(this).val();
        $('.same-as .well-title input[type=checkbox]:checked').parents('.well').find('[data-field=' + field + ']').val(val);
    });

    $('.same-as .well-title input[type=checkbox]').on('change', function () {
        if ($(this).is(':checked')) {
            $(this).parents('.well').find('[data-field=name]').val($('[name=admin_name]').val());
            $(this).parents('.well').find('[data-field=last_name]').val($('[name=admin_last_name]').val());
            $(this).parents('.well').find('[data-field=zip]').val($('[name=admin_zip]').val());
            $(this).parents('.well').find('[data-field=city]').val($('[name=admin_city]').val());
            $(this).parents('.well').find('[data-field=country]').val($('[name=admin_country]').val());
            $(this).parents('.well').find('[data-field=address1]').val($('[name=admin_address1]').val());
        }
    });
}
confDomain();
function confPriceUpdate(item) {
    $.each($(item).find(':selected'), function (i,el) {
        var price =$(el) .data('price');
        $(el).parents('.list-row').find('.price-zone').html('$' + price + ' USD');

    });
    }
function calculateTotal() {

    var total = $('.price-select').length;
    if (total == 0 && $('.configure-amount').length > 0) {
        location.reload();
    }
    var totalConfigured = 0;
    $.each($('.price-select'), function () {
        totalConfigured += ($(this).find(':selected').data('price'))*10/10;
    });
    $('.configure-amount').html(totalConfigured.toFixed(2));
    $('.total-domains').html(total);
}
calculateTotal();
confPriceUpdate('.price-select');
$('.price-select').on('change', function () {
    confPriceUpdate(this);
    calculateTotal();
});

function deleteItemFormConfigure(item) {
    var domain = $(item).data('remove-domain');

    var vars = {names: domain};
    var res = ajaxApiCall("/ajax/cart/configure", vars, 'DELETE');
    res.always(function () {
        if (res.responseText == 'ok') {
            $(item).parents('.list-row').remove();
            calculateTotal();
        }
    });
}
$('.delete-item-configure').on('click', function () {

    deleteItemFormConfigure(this);
    return false;
});

function renderRegisterRequest() {
    var request = {};
    request.names = [];
    request.periods = {};
    $.each($('.price-select'), function () {
        request.names.push($(this).data('domain'));
        request.periods[$(this).data('domain')] = $(this).val();
    });

    if ($('[name=type]:checked').val() == 'individual') {
        var owner = {
            type: 'individual',
            name: $("#name").val(),
            surname: $("#last_name").val(),
            country: $("[name=country]").val(),
            city: $("#city").val(),
            address: $("#address1").val(),
            zip: $("#zip").val()
        }
    } else {
        var owner = {
            type: 'legal',
            organisation: $("#org_name").val(),
            country: $(".registrant [name=country]").val(),
            city: $("#city").val(),
            address: $("#address1").val(),
            zip: $("#zip").val()
        }
    }

    var admin = {};
    admin.name = $('#admin_name').val();
    admin.surname = $('#admin_last_name').val();
    admin.email = $('#admin_email').val();
    admin.phone = $('#admin_phone').val();
    admin.country = $("[name=admin_country]").val();
    admin.city = $("#admin_city").val();
    admin.address = $("#admin_address1").val();
    admin.zip = $("#admin_zip").val();

    var billing = {};
    billing.name = $('#billing_name').val();
    billing.surname = $('#billing_last_name').val();
    billing.email = $('#billing_email').val();
    billing.phone = $('#billing_phone').val();
    billing.country = $("[name=billing_country]").val();
    billing.city = $("#billing_city").val();
    billing.address = $("#billing_address1").val();
    billing.zip = $("#billing_zip").val();

    var technical = {};
    technical.name = $('#tech_name').val();
    technical.surname = $('#tech_last_name').val();
    technical.email = $('#tech_email').val();
    technical.phone = $('#tech_phone').val();
    technical.country = $("[name=tech_country]").val();
    technical.city = $("#tech_city").val();
    technical.address = $("#tech_address1").val();
    technical.zip = $("#tech_zip").val();

    var ns = [];

    if ($('input#ip1').is(':checked')) {

        ns.push({name: $('input#ns1').val(), ip: $('input#ns1-ip').val()});
    } else {
        ns.push({name: $('input#ns1').val()});
    }
    if ($('input#ip2').is(':checked')) {
        ns.push({name: $('input#ns2').val(), ip: $('input#ns2-ip').val()});
    } else {
        ns.push({name: $('input#ns2').val()});
    }
    var additional = [];
    if($(".additional-fields input").length>0){

            $.each($(".additional-fields input"), function (i) {
                if($(this).is(':checkbox')){
                    if($(this).is(':checked')) {
                        request[$(this).prop('name')] = $(this).val();

                    } else {
                        request[$(this).prop('name')] = 0;
                    }

                } else {
                    request[$(this).prop('name')] = $(this).val();

                }
            })
    }

    if ($("[name=additional-ns]").is(":checked")) {
        if ($('input#ip3').is(':checked')) {
            ns.push({name: $('input#ns3').val(), ip: $('input#ns3-ip').val()});
        } else {
            ns.push({name: $('input#ns3').val()});
        }
        if ($('input#ip4').is(':checked')) {
            ns.push({name: $('input#ns4').val(), ip: $('input#ns4-ip').val()});
        } else {
            ns.push({name: $('input#ns4').val()});
        }
    }


    request.contacts = {};
    request.ns = ns;
    request.contacts.owner = owner;
    request.contacts.admin = admin;
    request.contacts.billing = billing;
    request.contacts.technical = technical;


    return request;
}

function validateRequest(request) {
    var parts = validateContact(request.contacts.admin) && validateContact(request.contacts.billing) && validateContact(request.contacts.technical) && validateNs(request.ns) && validateOwner(request.contacts.owner);

    if (parts) {
        return $(".form-validate").validate().checkForm();
    }
    return false;
}

function filledContact(contact) {
    return !(isEmpty(contact.name) || isEmpty(contact.surname) || isEmpty(contact.zip) || isEmpty(contact.email) || isEmpty(contact.phone) || isEmpty(contact.city) || isEmpty(contact.address));
}
function validateContact(contact) {
    return filledContact(contact);

}
function validateOwner(owner) {
    if ($('[name=type]:checked').val() == 'individual') {
        return !!(!isEmpty(owner.name) && !isEmpty(owner.surname) && !isEmpty(owner.zip) && !isEmpty(owner.country) && !isEmpty(owner.address) && !isEmpty(owner.city));

    } else {
        return !!(!isEmpty(owner['organisation']) && !isEmpty(owner.zip) && !isEmpty(owner.country) && !isEmpty(owner.address) && !isEmpty(owner.city));

    }
}

function validateNs(ns) {
    return ns.length >= 2;
}


$('.configur input').on('keyup change', function () {
    var requestBody = renderRegisterRequest();
    if (validateRequest(requestBody)) {
        $("#register-btn").removeClass('disabled');
    } else {
        $("#register-btn").addClass('disabled');
    }
});
$('.configur select').on('keyup change', function () {
    var requestBody = renderRegisterRequest();
    if (validateRequest(requestBody)) {
        $("#register-btn").removeClass('disabled');
    } else {
        $("#register-btn").addClass('disabled');
    }
});

function isEmpty(str) {
    return (!str || 0 === str.length);
}

$("#register-btn").on('click', function () {
    var requestBody = renderRegisterRequest();
    if (!$("#register-btn").hasClass("disabled")) {
        $("#register-btn").button('loading');
        if (validateRequest(requestBody)) {
            var res = ajaxApiCall("/ajax/domain", requestBody, 'POST');
            res.always(function () {
                $("#register-btn").button('reset');
            });

            statusModal(res, true);
        } else {
            $("#register-btn").button('loading');
            $("#register-btn").addClass('disabled');
        }
    }
});

if ($("#my-domains-list").length > 0) {
    registeredDomains();
    $("#my-domains-list table thead tr th").not(":first-child").on('click', function () {
        if (!lock) {
            if ($(this).attr("data-ordering") != undefined) {
                if ($(this).attr("data-ordering") == "desc") {
                    $(this).removeAttr('data-ordering');

                } else {
                    $(this).attr("data-ordering", "desc");

                }
            } else {
                $(this).attr("data-ordering", "asc");
            }
            renderFiltered();
        }

    });
    $('[name=search-domains]').on('keyup change', function () {
        clearTimeout(filter);
        var filter = setTimeout(function () {
            renderFiltered(true);
        }, 1000);
    });
}

function renderFiltered(term, page) {
    if (page == undefined) {
        page = 1;
    }
    if (!lock) {
        var searchTerm = $("[name=search-domains]").val();
        var filters = {};
        var filterLength = $("#my-domains-list table th").length;
        filterLength--;
        $.each($("#my-domains-list table th"), function (i, item) {
            if ($(item).attr('data-filter') != undefined && $(item).attr('data-ordering') != undefined) {

                filters[$(item).attr('data-filter')] = $(item).attr('data-ordering');

            }

            if (filterLength == i) {
                var str = {term: searchTerm, filters: filters};
                registeredDomains(str, term, page);

            }
        });
    }
}

var totalDomain = 0;
var lock = false;
function registeredDomains(filters, term, getpage) {
    if (filters == undefined) {
        filters = {};
    }
    if (getpage == undefined) {
        getpage = 1;
    }
    if (!lock) {
        lock = true;
        var res = ajaxApiCall("/ajax/domain?page=" + getpage, filters, "get");
        res.always(function () {
            lock = false;
            if (term == undefined) {
                $("#domain-loader").show();
            }
            $("#massage-area").html("");
            if (res.status == 403) {
                $("#my-domains-list table tbody").html("");
                $("#massage-area").html('<div class="text-danger text-center">You don\'t have registered domains yet.</div>');
                $("#domain-loader").hide();
            } else {
                var domains = $.parseJSON(res.responseText);
                $("#domain-loader").hide();
                if (domains.total <= 0) {
                    $("#my-domains-list table tbody").html("");
                    if (totalDomain > 0) {
                        $("#massage-area").html('<div class="text-danger text-center">Domains not found with added search terms.</div>');

                    } else {
                        $("#massage-area").html('<div class="text-danger text-center">You don\'t have registered domains yet.</div>');
                    }
                } else {
                    if (totalDomain == 0) {
                        totalDomain = domains.total;
                    }
                    $("#my-domains-list table tbody").html("");
                    var counter = 0;
                    $.each(domains.data, function (domain, data) {
                        counter++;
                        $("#my-domains-list table tbody").append(renderDomainRow(data));
                        $('[data-type=domain-modifier]').on('click', function () {
                            renderEditContact($(this).data('domain'));
                        });
                        $('[data-type=ns-modifier]').on('click', function () {
                            renderEditNS($(this).data('domain'));
                        });

                    });
                    makePaymentForResentDomain();
                    doWhois();
                    renew();

                    $("#my-domains-list .pagination").remove();
                    $("#my-domains-list .selected-renew").remove();
                    $("#my-domains-list").append(paginator(domains));
                    $("#my-domains-list").append('<button class="bulk-renew btn selected-renew disabled btn-sm border btn-primary btn-outline">Renew selected</button>');

                    onPageClick();
                    checkAllListenner();
                    if (domains.total <= counter) {
                        bulkRenew();
                    }
                    if (domains.per_page <= counter) {
                        bulkRenew();
                    }

                }
            }
        });
    }
}
function renderDomainRow(domain) {
    if (domain.expiration_date == null) {
        domain.expiration_date = '-';
    } else {
        domain.expiration_date = dateFormat((domain.expiration_date).mysqlToDate());
    }
    if (domain.registered_at == "0000-00-00 00:00:00") {
        domain.registered_at = dateFormat((domain.created_at).mysqlToDate());
    } else {
        domain.registered_at = dateFormat((domain.registered_at).mysqlToDate());
    }
    var registered = (domain.created_at).mysqlToDate();
    domain.created_at = dateFormat(registered);

    if (domain.exp_days == false || domain.status == "removal_process_started"|| domain.status == "expired"|| domain.status == "on_hold" || domain.status == "invalid") {
        domain.exp_days = '-';
    }
    var actions = "";
    var statusColor = "normal";
    var renewChechbox = '';
    if (domain.status != "Payment Required"  && domain.status != "removal_process_started" && domain.status != "invalid" && domain.status != "expired" && domain.status != "pending" ) {
        var checked = "";
        if($("#my-domains-list").find("[name=check-all]").is(":checked")){
            checked = "checked";
        }
        $("[name=check-all]").removeAttr("disabled").parents(".checkbox").removeClass("disabled");
        renewChechbox = '<label class="checkbox"><input type="checkbox"  data-domain="' + domain.name + '" '+checked+'><span class="replacer"></span></label>';
        actions += '<button data-action="whois" data-domain="' + domain.name + '" class="btn pull-left  with-space btn-xs btn-outline btn-primary">Whois</button>';
        if (domain.exp_days <= 35) {
            statusColor = "hint"
        }
        if (domain.exp_days <= 15) {
            statusColor = "warning"
        }
        if (domain.exp_days <= 0 || (domain.exp_days == '-' && domain.status == "on_hold")) {
            statusColor = "danger"
        }
        if(domain.status == "removal_process_started"){
            statusColor = "danger";
        }
        actions += '<button class="btn pull-left btn-outline btn-' + statusColor + ' with-space btn-xs " data-action="renew" data-domain="' + domain.name + '" >Renew</button>';

    }
    if(domain.status == 'Payment Required'){
        actions += '<button data-domain="'+domain.name+'" class="btn pull-left with-space btn-xs recent-make btn-primary">Make a payment</button>';
    }
    if(domain.status == 'expired'){
        actions += '<button data-domain="'+domain.name+'" class="btn pull-left with-space btn-xs recent-make btn-warning">Register</button>';
    }
    if (domain.status == "removal_process_started") {
        domain.status = "Pending Removal";
    }
    if (domain.status == "on_hold") {
        domain.status = "<span class='text-danger'>On Hold</span>";
    }
    if (domain.status == "active") {
        actions += '<div class="pull-left with-space btn-group"><button data-toggle="dropdown" aria-expanded="true" class="btn  btn-xs btn-primary  dropdown-toggle btn-outline ">Edit <i class="fa fa-angle-down btn-caret" aria-hidden="true"></i></button><ul class="dropdown-menu"><li><a href="#" data-type="ns-modifier" data-domain="' + domain.name + '">Manage Nameservers</a></li><li><a href="#" data-type="domain-modifier" data-domain="' + domain.name + '">Contact Information</a></li></ul></div>';
    }
    return "<tr><td>" + renewChechbox + "</td><td>" + domain.name + "</td><td>" + domain.registered_at + "</td><td class='text-" + statusColor + "'>" + domain.expiration_date + "</td><td class='text-" + statusColor + "'>" + domain.exp_days + "</td><td class='text-capitalize'>" + domain.status + "</td><td>" + actions + "</td></tr>";
}
function dateFormat(data) {
    var dayPrefix = "";
    var monthPrefix = "";
    if (data.getDate() < 10) {
        dayPrefix = "0";
    }
    if (data.getMonth() < 9) {
        monthPrefix = "0";
    }
    return dayPrefix + data.getDate() + "." + monthPrefix + (data.getMonth() + 1) + "." + data.getFullYear();
}

function renderEditContact(domain) {
    doModal(loaderHtml, null, 'edit_contact');
    $('#edit_contact').on('hidden.bs.modal', function () {
        $("#edit_contact .modal-body").html(loaderHtml);
    });
    $.get('ajax/modifyContacts/' + domain, function (data) {

        $("#edit_contact .modal-body").html(data);
        var domainList = '<div class="list-row p-btm">' + domain + '<input type="hidden" name="domain" value="' + domain + '"></div>';
        $("#modifed-domains").html('<div class="well without-border"><div class="list-row p-btm"><strong>Domain Name</strong></div>' + domainList + '</div>');
        $("#edit_contact .modal-dialog").addClass("modal-lg");
        $(".form-validate").validate();
        $("#edit_contact .checkbox label input").after("<span class='replacer'></span>");
        confDomain();
        if (!compareContacts('admin', 'billing')) {
            $('[name=same_as_admin]').prop('checked', false).trigger('change');
        }

        if (!compareContacts('admin', 'tech')) {
            $('[name=same_as_billing]').prop('checked', false).trigger('change');
        }
        $("#save-modifications").on('click', function (e) {
            e.preventDefault();
            var contacts = renderContactRequest();
            var name = $('[name=domain]').val();
            var entity = ($('#owner_entity').val()=='person')?'individual':'legal';
            var req = {name: name, type:entity, country: contacts.owner.country, contacts: contacts};
            var additional = [];
            if($(this).parents("#edit_contact").find(".additional-fields input").length>0){
                $.each($(this).parents("#edit_contact").find(".additional-fields input"), function (i) {
                    if($(this).is(':checkbox')){
                        console.log(2);

                        if($(this).is(':checked')) {
                            console.log(3);

                            req[$(this).prop('name')] = $(this).val();

                        } else {
                            console.log(4);

                            req[$(this).prop('name')] = 0;
                        }

                    } else {
                        req[$(this).prop('name')] = $(this).val();

                    }
                })
            }
            var res = ajaxApiCall('ajax/domain', req, 'PUT');
            statusModal(res);
        });
    });
}
function modalFix() {
    $(document).on('show.bs.modal', '.modal', function () {
        var zIndex = 1040 + (10 * $('.modal:visible').length);
        $(this).css('z-index', zIndex);
        setTimeout(function() {
            $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
        }, 0);
    });

    $(document).on('hidden.bs.modal', '.modal', function () {
        $('.modal:visible').length && $(document.body).addClass('modal-open');
    });
}
modalFix();
function renderEditNS(domain) {
    doModal(loaderHtml, null, 'RenderNs');
    $('#RenderNs').on('hidden.bs.modal', function () {
        $("#RenderNs .modal-body").html(loaderHtml);
    });
    $.get('ajax/modifyNs/' + domain, function (data) {

        $("#RenderNs .modal-body").html(data);
        var domainList = '<div class="list-row p-btm">' + domain + '<input type="hidden" name="domain" value="' + domain + '"></div>';
        $("#modifed-domains").html('<div class="well without-border"><div class="well without-border"><div class="list-row p-btm"><strong>Domain Name</strong></div>' + domainList + '</div>');
        $("#RenderNs .modal-dialog").addClass("modal-lg");
        $(".form-validate").validate();
        $("#RenderNs .checkbox label input").after("<span class='replacer'></span>");
        confDomain();
        $("#save-ns").on('click', function (e) {
            e.preventDefault();
            var name = $('[name=domain]').val();
            var ns = renderNsRequest();
            var res = ajaxApiCall('ajax/domain', {name: name, ns: ns}, 'PUT');

            statusModal(res);
        });

    });
}
function renderNsRequest() {
    var ns = [];

    if ($('input#ip1').is(':checked')) {

        ns.push({name: $('input#ns1').val(), ip: $('input#ns1-ip').val()});
    } else {
        ns.push({name: $('input#ns1').val()});
    }
    if ($('input#ip2').is(':checked')) {
        ns.push({name: $('input#ns2').val(), ip: $('input#ns2-ip').val()});
    } else {
        ns.push({name: $('input#ns2').val()});
    }


    if ($("[name=additional-ns]").is(":checked")) {
        if ($('input#ip3').is(':checked')) {
            ns.push({name: $('input#ns3').val(), ip: $('input#ns3-ip').val()});
        } else {
            ns.push({name: $('input#ns3').val()});
        }
        if ($('input#ip4').is(':checked')) {
            ns.push({name: $('input#ns4').val(), ip: $('input#ns4-ip').val()});
        } else {
            ns.push({name: $('input#ns4').val()});
        }
    }
    return ns;
}
function compareContacts(contact, competitor) {
    var baloon = true;
    $("[data-contact-group=" + competitor + "] input").each(function (index, item) {
        if ($(item).val() !== $("[data-contact-group=" + contact + "] input[data-field=" + $(item).data('field') + "]").val()) {
            baloon = false;
        }
    });
    $("[data-contact-group=" + competitor + "] select").each(function (index, item) {
        if ($(item).val() !== $("[data-contact-group=" + contact + "] select[data-field=" + $(item).data('field') + "]").val()) {
            baloon = false;
        }
    });
    return baloon;
}

function renderContactRequest() {
    var owner = {};
    owner.email = $('#owner_email').val();
    owner.phone = $('#owner_phone').val();
    owner.country = $("[name=owner_country]").val();
    owner.city = $("#owner_city").val();
    owner.address = $("#owner_address1").val();
    owner.zip = $("#owner_zip").val();

    var admin = {};
    admin.name = $('#admin_name').val();
    admin.surname = $('#admin_last_name').val();
    admin.email = $('#admin_email').val();
    admin.phone = $('#admin_phone').val();
    admin.country = $("[name=admin_country]").val();
    admin.city = $("#admin_city").val();
    admin.address = $("#admin_address1").val();
    admin.zip = $("#admin_zip").val();

    var billing = {};
    billing.name = $('#billing_name').val();
    billing.surname = $('#billing_last_name').val();
    billing.email = $('#billing_email').val();
    billing.phone = $('#billing_phone').val();
    billing.country = $("[name=billing_country]").val();
    billing.city = $("#billing_city").val();
    billing.address = $("#billing_address1").val();
    billing.zip = $("#billing_zip").val();

    var technical = {};
    technical.name = $('#tech_name').val();
    technical.surname = $('#tech_last_name').val();
    technical.email = $('#tech_email').val();
    technical.phone = $('#tech_phone').val();
    technical.country = $("[name=tech_country]").val();
    technical.city = $("#tech_city").val();
    technical.address = $("#tech_address1").val();
    technical.zip = $("#tech_zip").val();

    return {owner:owner, admin: admin, billing: billing, technical: technical}

}

function doWhois() {
    $("[data-action=whois]").on("click", function () {
        var domain = $(this).data('domain');
        doModal(loaderHtml, null, 'whoisModal');
        $.get('ajax/domain/whois/' + domain, function (data) {
            $("#whoisModal .modal-body").html("<pre>" + data + "</pre>");
        });
        $('#whoisModal').on('hidden.bs.modal', function () {
            $("#whoisModal.modal-body").html(loaderHtml);
        });
    })
}

function renew() {
    $('[data-action=renew]').on("click", function () {
        var domain = $(this).data('domain');
        doModal(loaderHtml, null, 'renewModal');
        renderRenew(domain);
        sendRenew();
        $('#renewModal').on('hidden.bs.modal', function () {
            $("#renewModal .modal-body").html(loaderHtml);
        })

    });
}

function renderRenew(domains) {
    if (!(domains instanceof Array)) {
        domains = [domains];

    }
    var html = '<div class="page-title"><h4>Renew Domains</h4></div><div class="well without-border" >';
    var hook = 0;

    $.each(domains, function (index, domain) {
         $.get('ajax/domain/'+domain,function (data) {
             hook++;
            html += '<div class="list-row" style="overflow: hidden; height:46px; margin-top:10px;">';
            html += '<div class="col-md-12 renewable" data-domain="' + domain + '">' + domain + '</div>';
            html += '<div class="col-md-12">';
            html += '<select name="' + domain + '" class="form-control input-sm">';
             $.each(data.prices, function (i, el) {
                 html += '<option value="'+el.year+'">'+el.year+' year';
                 if (el.year != "1") {
                     html +='s';
                 }
                 html +=' - $'+el.price;
                 html +='</option>';

             });
            html += '</select></div></div>';

             if(hook == domains.length){
                 html += '</div>';

                 hook = 0;
                 html += "<p></p><div class='form-group'><button class='btn btn-primary center-block' id='renew-list'> Confirm </button></div>";

                 $("#renewModal .modal-body").html(html);
                 sendRenew();
             }
        });

    });




}
function sendRenew(domain, year) {
    $("#renew-list").on('click', function () {
        var names = {};
        $('.renewable').each(function (index, item) {
            names[$(item).data('domain')] = $(item).parents(".list-row").find('select').val();
        });

        var res = ajaxApiCall('ajax/domain/renew', {domains: names}, "post");
        res.always(function () {
            if (res.status >= 200 && res.status < 300) {
                $('#renewModal').modal('hide');
                $('#statusModal').on('hidden.bs.modal', function (e) {
                    location.reload();
                });
            }
                statusModal(res);
            if (res.status >= 200 && res.status < 300) {
                $('#statusModal').on('hidden.bs.modal', function (e) {
                    location.reload();
                });
            }
        });
    });

}

function statusModal(res, action) {


    var addZ = "topModal";

    var html = '<div id="statusModal" class="modal ' + addZ + ' fade" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">';
    html += '<div class="modal-dialog"><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>';
    html += '<div class="modal-content">';
    html += '<div class="modal-body">';
    html += '<p>';
    html += loaderHtml;
    html += '</p>';
    html += '</div>';  // content
    html += '</div>';  // dialog
    html += '</div>';  // modalWindow
    $('body').append(html);
    $("#statusModal").modal();
    $("#statusModal").modal('show');

    $('#statusModal').on('hidden.bs.modal', function (e) {
        $(this).remove();
    });

    $("#statusModal").next('.modal-backdrop').css("z-index", '1051');

    res.always(function () {
        $("[data-type=balance-btn]").button('reset');
        var html = "";
        if (res.status >= 200 && res.status < 300) {
            var status = "block-success";
        } else {
            var status = "block-error";
        }
        if (res.responseText == 'fill-info') {
            var responseText = {};
            responseText.message = "Your account is not active. Still You can use all the tools, but you can't do financial transactions.";
            responseText.status = 403;
        } else {
            var responseText = $.parseJSON(res.responseText);
        }

        html += '<div class="' + status + '">';
        html += "<div class='status-icon'></div>";
        if (isEmpty(responseText.message)) {

            if (res.status >= 200 && res.status < 300) {
                if(!isEmpty(res.message)){
                    responseText.message = res.message;
                } else {
                    responseText.message = "All changes made successfully";

                }
            } else {
                responseText.message = "Errors Occurred";
                if (!isEmpty(responseText.errors.ns)) {
                    $.each(responseText.errors.ns, function (index, elem) {
                        responseText.message += '<p class="text-danger">' + elem + '</p>';
                    });
                }
                if (!isEmpty(responseText.errors.names)) {
                    $.each(responseText.errors.names, function (index, elem) {
                        responseText.message += '<p class="text-danger">'+ index + ': ' + elem + '</p>';
                    });
                }
                if (!isEmpty(responseText.errors.additional)) {
                    $.each(responseText.errors.additional[0], function (index, elem) {
                        responseText.message += '<p class="text-danger">'+ index + ': ' + elem + '</p>';
                    });
                }
                if (responseText.status == 402) {
                    responseText.message += '<p class="text-danger"> This transaction cannot be sent because there are insufficient funds available.</p><div class="form-group"><a href="/balance/fill" class="btn btn-primary">Fill Balance</a> </div>';
                }
                if (!isEmpty(responseText.errors.contacts)) {
                    if (!isEmpty(responseText.errors.contacts.admin)) {
                        $.each(responseText.errors.contacts.admin, function (index, elem) {
                            responseText.message += '<p class="text-danger">' + elem + '</p>';
                        });
                    }

                    if (!isEmpty(responseText.errors.contacts.billing)) {
                        if ($("input[name=same_as_admin]:checked").length < 1) {
                            $.each(responseText.errors.contacts.billing, function (index, elem) {
                                responseText.message += '<p class="text-danger">' + elem + '</p>';
                            });
                        }
                    }

                    if (!isEmpty(responseText.errors.contacts.technical)) {
                        if ($("input[name=same_as_billing]:checked").length < 1) {
                            $.each(responseText.errors.contacts.technical, function (index, elem) {
                                responseText.message += '<p class="text-danger">' + elem + '</p>';
                            });
                        }
                    }

                }

            }
        }
        if(!isEmpty(action) && action && res.status >= 200 && res.status < 300){
            responseText.message+= '<p><a href="/dashboard" class="btn btn-primary center-block" style="max-width: 350px">Manage your domains</a></p>';
            $("#statusModal").on('hidden.bs.modal', function (e) {
                window.location.replace("/dashboard");
            });
        }
        html += "<p class='message-zone'>" + responseText.message + "</div>";
        html += '</div>';
        $("#statusModal .modal-body").html(html);
    });

}
function paginator(data) {

    $("#my-domains-list .from").html(data.data.length);
    $("#my-domains-list .total").html(data.total);
    if (data.last_page > 1) {
        var html = '<ul class="pagination pagination-sm pull-right">';
        var prev = "";
        var prevPage = data.current_page - 1;
        if (data.current_page - 1 < 1) {
            prev = "disabled";
            prevPage = 1;
        }
        html += '<li class="' + prev + '"><a href="#my-domains-list" data-page="1" aria-label="Previous"><span aria-hidden="true">&lt;&lt;</span> </a> </li>';
        html += '<li class="' + prev + '"><a href="#my-domains-list" data-page="' + prevPage + '" aria-label="First"><span aria-hidden="true"> &lt; </span> </a> </li>';

        var offset = 4;
        if(data.current_page - offset>1){
            html += '<li class="' + (data.current_page - offset) + '"><a href="#my-domains-list" data-page="' +(data.current_page - offset) + '" aria-label="Next"><span aria-hidden="true"> ... </span> </a> </li>';
        }
        for (var i = 1; i <= data.last_page; i++) {
            if (data.total <= offset * 2 + 1  || (data.current_page - offset <=i && data.current_page + offset >=i )) {
                var active = "";
                if (data.current_page == i) {
                    active = " active ";
                }
                html += '<li class="' + active + '"><a href="#my-domains-list" data-page="' + i + '" aria-label="Next"><span aria-hidden="true"> ' + i + ' </span> </a> </li>';


            }
        }
        if(data.current_page + offset<data.last_page){
            html += '<li class="' + (data.current_page + offset) + '"><a href="#my-domains-list" data-page="' + (data.current_page + offset) + '" aria-label="Next"><span aria-hidden="true"> ... </span> </a> </li>';
        }
        var last = "";
        var nextPage = (data.current_page + 1);
        if (data.current_page + 1 > data.last_page) {
            last = "disabled";
            nextPage = data.last_page;
        }
        html += '<li class="' + last + '"><a href="#my-domains-list"  data-page="' + nextPage + '" aria-label="Next"><span aria-hidden="true"> &gt; </span> </a> </li>';
        html += '<li class="' + last + '"><a href="#my-domains-list" data-page="' + data.last_page + '" aria-label="Last"><span aria-hidden="true">&gt;&gt;</span> </a> </li>';

        html += '</ul>';
        return html;
    }
    return "";
}

function onPageClick() {
    $(".pagination a").on('click', function () {
        renderFiltered(true, $(this).data("page"));
        checkAllListenner();
    });

}

function bulkRenew() {

    $(".bulk-renew").on('click', function () {


        if ($("#my-domains-list tbody input[type=checkbox]:checked").length > 0) {
            $(".bulk-renew").removeClass("disabled");
            var domains = [];
            $("#my-domains-list tbody input[type=checkbox]:checked").each(function (i, el) {
                domains.push($(this).data('domain'));
            });
            doModal(loaderHtml, null, 'renewModal');
            renderRenew(domains);
            $('#renewModal').on('hidden.bs.modal', function () {
                $("#renewModal .modal-body").html(loaderHtml);
            });
            sendRenew();
        } else {
            $(".bulk-renew").addClass("disabled");
        }
        });


}
function makePaymentForResentDomain() {
    var domains = [];
    $(".recent-make").on('click', function(){
        domains.push({domain: $(this).data('domain'), year: 1});
        var vars = {};
        vars.domains = domains;
        var res = ajaxApiCall("/ajax/cart/configure", vars, 'POST');
        res.always(function () {
            if (res.responseText == 'ok') {
                window.location.href = "/cart/configure";
            }
        });
    });

}
String.prototype.mysqlToDate = String.prototype.mysqlToDate || function() {
        var t = this.split(/[- :]/);
        return new Date(t[0], t[1]-1, t[2], t[3]||0, t[4]||0, t[5]||0);
};