$(document).on('click', '.item_delete', function () {
    $($(this).parents('.item-row')).slideUp("slow", function () {
        $(this).remove();
    });
});
$(function () {
    $("#datepicker").datepicker("option", "dateFormat", 'd MM, y');
});


var timeoutSeter = false;
$('.special_search').on("keyup", function (evt) {
    if (evt.keyCode == 27) {
        // alert('Esc key pressed.');
        $(this).val("");
        $(this).blur();
    }

    clearTimeout(timeoutSeter);
    var domain = $(this).val().trim();
    timeoutSeter = setTimeout(function () {
        $.get('/backend/DomainCRUD', {domain: domain}, function (data) {
            $("#admin-domain-list").html(data);
        })
    }, 1000);
});

var timeoutSeter = false;
$('.special_search').on("mouseup", function () {

    clearTimeout(timeoutSeter);
    var domain = $(this).val().trim();
    timeoutSeter = setTimeout(function () {
        $.get('/backend/DomainCRUD', {domain: domain}, function (data) {
            $("#admin-domain-list").html(data);
        })
    }, 1000);
});

var timeoutSeter1 = false;
$('.special_search1').on("keyup", function (evt) {
    if (evt.keyCode == 27) {
        // alert('Esc key pressed.');
        $(this).val("");
        $(this).blur();
    }

    clearTimeout(timeoutSeter1);
    var invoice = $(this).val().trim();
    timeoutSeter1 = setTimeout(function () {
        $.get('/backend/billing/InvoiceCRUD', {invoice: invoice}, function (data) {
            $("#admin-invoice-list").html(data);
        })
    }, 1000);
});

var timeoutSeter1 = false;
$('.special_search1').on("mouseup", function () {

    clearTimeout(timeoutSeter1);
    var invoice = $(this).val().trim();
    timeoutSeter1 = setTimeout(function () {
        $.get('/backend/billing/InvoiceCRUD', {invoice: invoice}, function (data) {
            $("#admin-invoice-list").html(data);
        })
    }, 1000);
});