/**
 * Created by Programmer on 14.09.2016.
 */

var ww = document.body.clientWidth;

$(document).ready(function () {
    $(".nav_1 li a").each(function () {
        if ($(this).next().length > 0) {
            $(this).addClass("parent");
        }
    });
    jQuery("#mobile-menu-block .nav_1").css("height", $("body").height() - 81);

    $('.nav_1 li a[href$="#"]').addClass("anchored");
    $('.nav_1 li a:not(.anchored)').html(function (e) {

        return "<span class='link-wrap'>" + $(this).text() + "</span>";
    });
    $('.nav_1 li a .link-wrap').on('click', function (e) {
        $(this).parent("a").parent("li").toggleClass("hover");
        var relocation = $(this).parent('a').attr('href');
        window.location.href = relocation;
    });
    $(".toggleMenu_1").click(function (e) {
        e.preventDefault();
        $(this).toggleClass("active");
        $(".nav_1").toggle();
    });

    $("body").append('<div class="black-transparent-bg"></div>');

    adjustMenu();
});

$(window).bind('resize orientationchange', function () {
    ww = document.body.clientWidth;
    adjustMenu();
    if (ww > 1040 && $("#mobile-menu-btn").hasClass("open")) {
        jQuery("#mobile-menu-btn").trigger("click");

    }
});

var adjustMenu = function () {
    if (ww < 1040) {
        if (!$(".toggleMenu_1").hasClass("active")) {
            $(".nav_1").hide();
        } else {
            $(".nav_1").show();
        }
        $(".nav_1 li").unbind('mouseenter mouseleave touchstart');
        $(".nav_1 li a.parent").unbind('click mouseenter mouseleave touchstart').bind('click', function (e) {
            // must be attached to anchor element to prevent bubbling
            if ($(this).parent().parent().parent().hasClass("hover")) {
                $('.nav_1 li ul li').not($(this).parent("li")).removeClass('hover');
                $(this).parent("li").toggleClass("hover");
            } else {
                $('.nav_1 li').not($(this).parent("li")).removeClass('hover');
                $(this).parent("li").toggleClass("hover");
            }

            jQuery("#mobile-menu-block .nav_1").css("height", $("body").height() - 81);
            e.preventDefault();

        });
    }
    else if (ww >= 1040) {

        $(".nav_1 li").removeClass("hover");
        $(".nav_1 li a").unbind('click');
        $(".nav_1 li").unbind('mouseenter mouseleave').bind('mouseenter mouseleave', function () {
            // must be attached to li so that mouseleave is not triggered when hover over submenu
            $(this).toggleClass('hover');
        });
    }
};
menuListener();
function menuListener() {
    jQuery("#mobile-menu-btn").click(function () {
        $(this).toggleClass('open');
        if (jQuery(this).hasClass("open")) {
            jQuery(".black-transparent-bg").css("height", $("body").height() + 200);
            jQuery("#mobile-menu-block .nav_1").animate({
                right: 0
            }, 200, function () {
            });
            jQuery(".black-transparent-bg").show();
            jQuery(".black-transparent-bg").animate({
                opacity: 0.5
            }, 500, function () {
            });

            //desable scrolling
            document.body.style.overflow = 'hidden';
        } else {
            jQuery("#mobile-menu-block .nav_1").animate({
                right: "-100%"
            }, 200, function () {
            });

            jQuery(".black-transparent-bg").animate({
                opacity: 0

            }, 400, function () {
                jQuery(".black-transparent-bg").hide();
            });
            document.body.style.overflow = 'auto';
            //enable scrolling
        }

        jQuery(".black-transparent-bg").unbind('click').bind('click', function (e) {
            // must be attached to anchor element to prevent bubbling
            e.preventDefault();

            jQuery("#mobile-menu-btn").trigger("click");
        });
    });
}

$("#transfer-btn").click(function (event) {

    if ($.trim($('[name=domains]').val()) != "") {
        var domains = getDomainTransferList();

        renderDomainResultTransfer(domains);
    }
    return false;
});
function getDomainTransferList() {
    var domains = $('[name=domains]').val().split('\n');
    var list = [];
    $.each(domains, function (index, item) {
        item = $.trim(item);
        if (item != "") {
            list.push(item);
        }
    });
    return uniques(list);
}
function renderDomainResultTransfer(domains) {
    $("#search-result").html('');
    var rendered = '<div class="well transfer-well">';
    rendered += '<div class="list-row"><div class="checkbox disabled"><label><strong>Domains to transfer</strong></label></div></div><div data-group="checkbox">';
    $.each(domains, function (index, item) {
        rendered += '<div class="list-row"  data-transfer=\'' + item + '\'" data-domain=\'' + clearId(item) + '\'"><div class="row"><div class="checkbox  disabled col-sm-6 col-xs-12 dom-name"><label><div class="text-overflow">';
        rendered += '<span class="bg-well">' + escapeHtml(item) + '</span></div><!--end-texoverflow--></label></div><!--end-checkbox-->';
        rendered += '<div class="col-sm-4 text-left col-xs-12  dom-status"></div><div class="col-sm-7 col-xs-12  dom-price"> ' + loaderHtml + ' </div> <div class="col-sm-6 col-xs-12  dom-action"></div>';
        rendered += '</div><!--end-row--></div><!--end-list-row-->';
    });
    rendered += '</div>';
    rendered += "<div class='btn-list pull-right'><button id='submit-transfer-all' class='bolder btn btn-primary disabled'>Submit Transfer for All Domains</button>";
    rendered += "<button id='applyWithDocButton' class='btn btn-primary-outline bolder  pull-right'>Apply with document</a></div></div>";

    $("#transfer-result").html(rendered);
    $("#submit-transfer-all").on('click', function () {
        sendAllFilled();

    });
    applyWithDocumentButton();

    getDomainResultTransfer(domains);
}
var locAjax = false;
function getDomainResultTransfer(domains) {
    var list = clearDomainList(domains);
    var requestInterval = setInterval(function () {
        if (list.length > 0) {
            if (!locAjax) {
                locAjax = true;
                var result = ajaxApiCall("/ajax/domain/transfer/" + list[0]);
                result.always(function (data) {
                    locAjax = false;
                    var response = $.parseJSON(result.responseText);
                    var rowId = clearId(list[0]);
                    if (response.locStatus == true) {
                        setTransferLocked(rowId);
                    } else {
                        if (response.status == 400) {
                            setInvalidExtensionUnavailable(rowId);
                        } else {
                            if (response.status == 404) {
                                setAvailableTransfer(rowId, response);

                            } else {
                                if (response.codeTransfer == true && response.docTransfer == true) {
                                    setTransferCodeTwo(rowId);

                                } else {
                                    if (response.selfSignCode == true) {
                                        setTransferCode(rowId);
                                    } else {
                                        if (response.codeTransfer == false && response.docTransfer == true) {
                                            setTransferWithDocument(rowId);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    removeItemByValue(list, list[0]);
                });
            }
        } else {
            transferWithDocument();

            clearInterval(requestInterval);
        }
    }, 1000);
}

function setInvalidExtensionUnavailable(rowId) {
    $('[data-domain=' + rowId + "] div .dom-price").html("");
    $('[data-domain=' + rowId + "] .dom-status").html("<span class='label label-danger'> Unavailable</span>");
    $('[data-domain=' + rowId + "] div .dom-price").addClass('muted-transfer pdng-20').html("Unsupported Extension");
}

function setAvailableTransfer(rowId, result) {
    $('[data-domain=' + rowId + "] .dom-status").html("<span class='label label-success'>Not Registered</span>");
    var prices = result.prices;
    if (prices > 1) {
        <!-- Split button -->
        var btn = '<div class="btn-group">';
        btn += '<button type="button" data-loading-text="Adding..." id="btn-' + rowId + '" data-domain="' + result.domain + '" data-year="' + prices[0].year + '" class="btn btn-sm btn-primary price-sender"><span class="fa fa-shopping-cart mgr-5"></span><strong>Add to Cart</strong></button>';
        btn += '<button type="button" class="btn price-sender btn-primary btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">';
        btn += '<span class="caret"></span>';
        btn += '<span class="sr-only">Toggle Dropdown</span>';
        btn += '</button>';
        btn += '<ul class="dropdown-menu">';
        $.each(prices, function (index, price) {
            btn += '<li><a href="#" data-row="' + rowId + '" class="price-changer" data-year="' + price.year + '" data-price="' + price.price + '">' + price.year + ' Year/s @ $' + price.price + ' USD</a></li>';
        });
        btn += '</ul>';
        btn += '</div>';
    } else {
        var btn = '<button data-loading-text="Adding..." class="btn mrg-5-0 btn-sm pull-right  btn-primary price-sender" id="btn-' + rowId + '" data-domain="' + result.domain + '" data-year="' + prices[0].year + '" btn-sm"><span class="fa fa-shopping-cart mgr-5"></span><strong>Add to Cart</strong></button>';
    }
    $('[data-domain=' + rowId + "] .dom-action").css('padding', '10px').html(btn);
    $('[data-domain=' + rowId + "] .checkbox").removeClass("disabled");
    updatePrice(rowId, prices[0].price);
    $('[data-domain=' + rowId + "] .checkbox input[type=checkbox]").removeAttr("disabled").attr("checked", "checked");
    $('[name=check-all]').removeAttr("disabled").attr("checked", "checked").parent("label").parent(".checkbox").removeClass("disabled");
    $('#add-to-cart').removeClass("disabled");
    $(".price-changer").on('click', function (event) {
        event.preventDefault();
        updatePrice($(this).data('row'), $(this).data('price'), $(this).data('year'));
        $(".open").removeClass("open");
        return false;
    });
    $("#btn-" + rowId).click(function () {
        addToCart($(this));
    });
    $("#add-to-cart").click(function () {
        sendAllSelected();
    });
}

function setTransferCode(rowId) {
    $('[data-domain=' + rowId + "] div .dom-price").html("");
    $('[data-domain=' + rowId + "] .dom-status").css("padding-top", "25px").html("Transfer Code");
    $('[data-domain=' + rowId + "] div .dom-price").css('padding', '20px 0').html("<input type='text' class='transfer-code form-control input-sm'><button class='btn btn-primary disabled btn-sm trf-btn'>Submit</button>");
    $('[data-domain=' + rowId + "] div .dom-action").addClass('muted-transfer').html("Check the administrative or technical contact’s email of this domain to get the Transfer code.");
    sendTransferCode();
    activateTrButton();
}
function sendTransferCode() {
    $('.trf-btn').on('click', function () {
        if (!$(this).hasClass('disabled')) {
            var btn = this;
            $(this).button('loading');
            var code = $(this).prev('.transfer-code').val();
            var domain = $(this).parents(".list-row").data("transfer");
            var res = ajaxApiCall('/ajax/transfer', {domain: domain, code: code}, 'POST');
            doModal(loaderHtml, 1, "TransferWithCodeModal");
            res.always(function () {
                var html = "";
                $(btn).button('reset');

                if (res.status == 200) {
                    var status = "block-success";
                } else {
                    var status = "block-error";
                }

                var responseText = $.parseJSON(res.responseText);
                html += '<div class="' + status + '">';
                html += "<div class='status-icon'></div>";


                if (responseText.items != undefined) {
                    $.each(responseText.items, function (n, item) {
                        html += '<strong>' + item.name + '</strong> ' + item.textMassage;
                        if (item.status == 200) {
                            setSuccessTransfer(item.name, '<img src="/img/icons/success.svg" style="margin-right: 20px;margin-top: -5px;">' + item.textMassage);

                        }
                        if (item.status == 201) {
                            setSuccessTransfer(item.name, '<img src="/img/icons/inform.svg" style="margin-right: 20px;margin-top: -5px;">' + item.textMassage);
                        }
                    });
                    if (res.status == 200) {
                        html += '<div class="clearfix"></div><div class="col-sm-12" style="margin-top: 30px;"><button type="button" class="btn btn-primary btn-primary-outline btn-block" data-dismiss="modal" aria-label="Close">Go back to transfer</button></div><div class="col-sm-12" style="margin-top: 30px;"><a href="/domains" class="btn btn-primary btn-block" style="padding: 12px 22px;">Manage your Domains</button></div>'
                    }
                    if (res.status > 200) {
                        html += '<div class="clearfix"></div><div class="col-sm-12" style="margin-top: 30px;"><button type="button" class="btn btn-primary btn-primary-outline btn-block" data-dismiss="modal" aria-label="Close">Go back to transfer</button></div><div class="col-sm-12" style="margin-top: 30px;"><button type="button" class="btn btn-primary  btn-block" data-dismiss="modal" aria-label="Close">Continue</button></div>'
                    }
                    html += '</div>';
                } else {
                    html += '<p>' + responseText.textMassage + '</p>';
                    html += '<div class="clearfix"></div><div class="col-sm-24" style="margin-top: 30px;"><button type="button" class="btn btn-primary btn-primary-outline btn-block" data-dismiss="modal" aria-label="Close">Go back to transfer</button></div>'

                }

                $("#TransferWithCodeModal .modal-body").html(html);
            });
        }


    });
}
function setSuccessTransfer(domainName, massage) {
    var rowId = clearId(domainName);
    $('[data-domain=' + rowId + "] div .dom-price").html("");
    $('[data-domain=' + rowId + "] .dom-status").removeClass('col-sm-3').addClass('col-sm-7 muted-transfer').html(massage);
    $('[data-domain=' + rowId + "] div .dom-action").remove();
    $('[data-domain=' + rowId + "] div .dom-price").remove();
}
function setTransferCodeTwo(rowId) {
    $('[data-domain=' + rowId + "] div .dom-price").html("");
    $('[data-domain=' + rowId + "] .dom-status").css("padding-top", "25px").html("Transfer Code");
    $('[data-domain=' + rowId + "] div .dom-price").css('padding', '20px 0').html("<input type='text' class='transfer-code form-control input-sm'><button class='btn disabled btn-primary btn-sm trf-btn'>Submit</button>");
    $('[data-domain=' + rowId + "] div .dom-action").addClass('muted-transfer').html("Get the transfer code from your current Registrar, or <a href='javascript:void(0);'  class='transferWithDocument strong'>Transfer with document.</a>");
    sendTransferCode();
    activateTrButton();
}
function setTransferWithDocument(rowId) {
    $('[data-domain=' + rowId + "] div .dom-price").html("");
    $('[data-domain=' + rowId + "] .dom-status").removeClass('col-sm-3').addClass('col-sm-7 muted-transfer').html("This domain is not in our system now. Please apply through the form <a href='javascript:void(0);' class='transferWithDocument strong'>Transfer with document.</a>");
    $('[data-domain=' + rowId + "] div .dom-action").remove();
    $('[data-domain=' + rowId + "] div .dom-price").remove();
}

function setTransferLocked(rowId) {
    $('[data-domain=' + rowId + "] div .dom-price").html("");
    $('[data-domain=' + rowId + "] .dom-status").html("<span class='label label-warning pull-left'> Locked</span>");
    $('[data-domain=' + rowId + "] div .dom-price").removeClass('col-sm-6').addClass('col-sm-12 muted-transfer').html("This domain is locked. Go to <a href='https://www.amnic.net/' target='_blank' class='strong'>Site of Amnic</a>. Fill all necessary fields and on page you’ll see after that set the Registrar Transfer option as Allowed.");
    $('[data-domain=' + rowId + "] div .dom-action").remove();
}
function transferWithDocument() {
    $('.transferWithDocument').on('click', function () {
        var item = $(this).parents(".list-row").data("transfer");
        getTransferWithDocumentModal(item);
    })
}
function getTransferWithDocumentModal(item) {

    if (item == undefined) {
        item = '';
    }

    doModal(loaderHtml, null, 'transferWithDocument');
    $.get('/ajax/domain/document/transfer/' + item, function (data) {
        $("#transferWithDocument .modal-dialog").addClass('modal-lg');
        $("#transferWithDocument .modal-body").html(data);
        $("#download-word").click(function () {
            submitTransferForm('doc');
        });
        $("#download-pdf").click(function () {
            submitTransferForm('pdf');
        });
    });
}
function sendAllFilled() {
    var count = $('.transfer-code').length;
    if (!$("#submit-transfer-all").hasClass('disabled')) {
        var boolbulator = 0;
        var listing = {}
        $.each($('.transfer-code'), function (i, item) {
            boolbulator++;
            var code = $(item).val();
            if (!isEmpty(code)) {
                listing[$(this).parents(".list-row").data("transfer")] = code;
            }
            if (boolbulator == count) {
                var res = ajaxApiCall('/ajax/transfer', {domains: listing}, 'POST');
                doModal(loaderHtml, 1, "TransferWithCodeModal");
                res.always(function () {
                    var html = "";

                    if (res.status == 200) {
                        var status = "block-success";
                    } else {
                        var status = "block-error";
                    }

                    var responseText = $.parseJSON(res.responseText);
                    html += '<div class="' + status + '">';
                    html += "<div class='status-icon'></div>";


                    if (responseText.items != undefined) {
                        $.each(responseText.items, function (n, item) {
                            html += '<strong>' + item.name + '</strong> ' + item.textMassage +'<br>';
                            if (item.status == 200) {
                                setSuccessTransfer(item.name, '<img src="/img/icons/success.svg" style="margin-right: 20px;margin-top: -5px;">' + item.textMassage);

                            }
                            if (item.status == 201) {
                                setSuccessTransfer(item.name, '<img src="/img/icons/inform.svg" style="margin-right: 20px;margin-top: -5px;">' + item.textMassage);
                            }
                        });
                        if (res.status == 200) {
                            html += '<div class="clearfix"></div><div class="col-sm-12" style="margin-top: 30px;"><button type="button" class="btn btn-primary btn-primary-outline btn-block" data-dismiss="modal" aria-label="Close">Go back to transfer</button></div><div class="col-sm-12" style="margin-top: 30px;"><a href="/domains" class="btn btn-primary btn-block" style="padding: 12px 22px;">Manage your Domains</button></div>'
                        }
                        if (res.status > 200) {
                            html += '<div class="clearfix"></div><div class="col-sm-12" style="margin-top: 30px;"><button type="button" class="btn btn-primary btn-primary-outline btn-block" data-dismiss="modal" aria-label="Close">Go back to transfer</button></div><div class="col-sm-12" style="margin-top: 30px;"><button type="button" class="btn btn-primary  btn-block" data-dismiss="modal" aria-label="Close">Continue</button></div>'
                        }
                        html += '</div>';
                    } else {
                        html += '<p>' + responseText.textMassage + '</p>';
                        html += '<div class="clearfix"></div><div class="col-sm-24" style="margin-top: 30px;"><button type="button" class="btn btn-primary btn-primary-outline btn-block" data-dismiss="modal" aria-label="Close">Go back to transfer</button></div>'

                    }

                    $("#TransferWithCodeModal .modal-body").html(html);
                });
            }
        })
    }
}
function submitTransferForm(docType) {
    if (docType != undefined) {
        $("#trans-form").on('submit', function () {
            if (docType == 'doc') {
                $(this).attr('action', '/doc/transfer/request').unbind('submit').submit();
            }
            if (docType == 'pdf') {
                $(this).attr('action', '/pdf/transfer/request').unbind('submit').submit();
            }
        });
    }
    return false;
}

function activateTrButton() {
    $('.transfer-code').on('input', function () {
        var trfs = false;
        $.each($('.transfer-code'), function (i, item) {
            if (!isEmpty($(item).val())) {
                trfs = true;
            }
        });

        if (trfs) {
            $("#submit-transfer-all").removeClass('disabled');
        } else {
            $("#submit-transfer-all").addClass('disabled');

        }

        if (!isEmpty($(this).val())) {
            $(this).next('button').removeClass('disabled');
        } else {
            $(this).next('button').addClass('disabled');
        }
    });
}

function applyWithDocumentButton() {
    $("#domain-name").on('input', function () {
        $('.dont-tach-this').remove();
        if (!isEmpty($(this).val())) {
            $('#send-single-domain').removeClass('disabled');
        } else {
            $('#send-single-domain').addClass('disabled');
        }
    });
    $("#applyWithDocButton").on('click', function () {

        doModal(loaderHtml, false, "DocumentModalFirst");
        $.get('/ajax/transfer/first', function (data) {
            $("#DocumentModalFirst .modal-dialog").addClass('modal-lg');
            $("#DocumentModalFirst .modal-body").html(data);
            $("#domain-transfer-with-doc").on('submit', function () {
                if (!isEmpty($("#domain-name").val())) {

                    var result = ajaxApiCall("/ajax/domain/transfer/" + $("#domain-name").val());
                    $("#send-single-domain").button('loading');
                    result.always(function (data) {
                        $("#send-single-domain").button('reset');
                        locAjax = false;
                        var response = $.parseJSON(result.responseText);
                        if (response.docTransfer == true) {
                            $('#DocumentModalFirst').modal('hide');
                            doModal(loaderHtml, null, 'transferWithDocument');
                            $.get('/ajax/domain/document/transfer/' + $('#domain-name').val(), function (data) {
                                $("#transferWithDocument .modal-dialog").addClass('modal-lg');
                                $("#transferWithDocument .modal-body").html(data);
                                $("#download-word").click(function () {
                                    submitTransferForm('doc');
                                });
                                $("#download-pdf").click(function () {
                                    submitTransferForm('pdf');
                                });
                            });
                        } else {
                            $('.dont-tach-this').remove();
                            $("#domain-name").after('<span class="dont-tach-this text-danger">This Domain can\'t be transferred via documents</span>');
                        }
                    });
                }
                return false;
            })
        });
    });
}